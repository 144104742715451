import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-roadmap',
  templateUrl: './roadmap.component.html',
  styleUrls: ['./roadmap.component.scss']
})
export class RoadmapComponent implements OnInit {

  constructor() { }
  stages = [
    {
      header: "Навчання  на платформі",
      text: "Ми розробили базовий безкоштовний курс з основ трейдингу, де ми розповідаємо як працює криптовалютний ринок, знайомимо з торговим терміналом, а також розбираємо 2 основні торгові стратегії компанії.\n\nПісля кожного уроку є невеликі завдання для кращого засвоєння матеріалу.\n\n",
      icon: "../../../../assets/images/plan1.png",
      timeline: "Навчання займає 3-5 днів.",
      color: "#759BCB"
    },
    {
      header: "Робота з ментором",
      text: "Після успішного закінчення навчання на платформі, ти потрапляєш на  Discord сервер. Старший трейдер стає твоїм ментором і допомагає краще розібратися зі стратегіями, пропрацювати помилки і покращити торгівлю на емуляторі.\n\n",
      icon: "../../../../assets/images/plan2.png",
      timeline: "Період роботи з ментором триває 5-14 днів залежно від результатів.",
      color: "#9B8BE2"
    },
    {
      header: "Торгівля на реальному рахунку",
      text: "Коли твої результати торгівлі вищі за середні, ми даємо тобі перші долари на ордер і ти починаєш працювати на реальному рахунку, щоб набити руку і накопичити подушку (до 50-80$). У цей період старший трейдер допомагає тобі покращити торгівлю. Ордер трейдера поступово збільшується від 30$ до 800$.",
      icon: "../../../../assets/images/plan3.png",
      timeline: "",
      color: "#1C2978"
    },
    {
      header: "Реєстрація в компанії",
      text: "Коли ти почуваєш себе вже впевненим у торгівлі і маєш хороші результати угод та ордер 1000$. Стажер стає зареєстрованим трейдером в компанії.",
      icon: "../../../../assets/images/plan4.png",
      timeline: "",
      color: "#759BCB"
    },
    {
      header: "Підвищення ордера",
      text: "На цьому етапі ти вже можеш виводити кошти від прибуткових угод або накопичувати їх на подушці для збільшення ордеру.",
      icon: "../../../../assets/images/plan5.png",
      timeline: "",
      color: "#9B8BE2"
    },
  ]
  ngOnInit(): void {
  }

}
