import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';


import { HomeComponent } from './pages/home/home.component';
import { RegistrationComponent } from './pages/home/registration/registration.component'
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { LoginComponent } from './pages/home/login/login.component';
import { ProfileComponent } from './pages/dashboard/profile/profile.component';
import { LessonsComponent } from './pages/dashboard/lessons/lessons.component';
import { TestsComponent } from './pages/dashboard/tests/tests.component';
import { HelloPageComponent } from './pages/dashboard/hello-page/hello-page.component';
import { PrivatePolicyComponent } from './pages/private-policy/private-policy/private-policy.component';
import { TermsConditionsComponent } from './pages/private-policy/terms-conditions/terms-conditions.component';
import { AngularFireAuthGuard, redirectUnauthorizedTo} from '@angular/fire/compat/auth-guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);

const routes: Routes = [
  {
    path: '', component: HomeComponent
  },
  {
    path: 'registration', component: RegistrationComponent
  },
  {
    path: 'dashboard', component: DashboardComponent,
    children: [
      { path: '', redirectTo: 'lesson/company', pathMatch: 'full' },
      { path: 'profile', component: ProfileComponent },
      { path: 'lesson/:id', component: LessonsComponent },
      { path: 'test/:id', component: TestsComponent },
      { path: 'welcome', component: HelloPageComponent }
    ],
    canActivate: [AngularFireAuthGuard],data: { authGuardPipe: redirectUnauthorizedToLogin }
  },

  {
    path: 'login', component: LoginComponent
  },

  {
    path: 'privacy_policy', component: PrivatePolicyComponent
  },
  {
    path: 'terms&conditions', component: TermsConditionsComponent
  },
  // {
  //   path: 'post/:id', component: PostComponent
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    { scrollPositionRestoration: 'enabled' }
  ),],
  exports: [RouterModule]
})
export class AppRoutingModule { }
