import { Component } from '@angular/core';
import { OnInit } from '@angular/core';
import { SuccesMessageComponent } from './succes-message/succes-message.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GetInfoService } from '../../../shared/services/get-info.service';
import { HelpersService } from '../../../shared/services/helpers.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MatRadioChange } from '@angular/material/radio';
import { Router } from '@angular/router';
import { LoaderService } from '../../../shared/services/loading/loader.service';
@Component({
  selector: 'app-tests',
  templateUrl: './tests.component.html',
  styleUrls: ['./tests.component.scss'],
  
})
export class TestsComponent implements OnInit {
  test: any;
  isRender = false;
  answerControl = false;
  answers: { [k: string]: any } = {};
  testId: any;
  constructor(
    public getInfo: GetInfoService,
    public helpers: HelpersService,
    private route: ActivatedRoute,
    private _location: Location,
    public router: Router,
    public dialog: MatDialog,
    public loadingService: LoaderService
  ) { }

  ngOnInit(): void {
    this.route.params
      .subscribe(async (params: any) => {
        this.helpers.setLastVisitedPage(this.router.url);
        let id = (params['id']).trim();
        this.testId = id;
        this.answers = { id: id };
        (await this.getInfo.getTest(id)).subscribe(result => {
          this.test = result
          if (this.test) { this.isRender = true }
        })
      }
      )
    this.getInfo.getTest
  }
  radioChange(event: MatRadioChange, id: string) {
    let a = ""
    a = event.value.toString();
    let b = id.toString();
    this.answers[`${b}`] = a;
    if (Object.keys(this.answers).length == Object.keys(this.test).length + 1) {
      this.answerControl = true;
    }
  }
  backClicked() {
    this._location.back();
  }
  async checkAnswers() {
    this.loadingService.setLoading(true);
    (await this.getInfo.checkAnswers(JSON.stringify(this.answers))).subscribe(answers => {
      const result = JSON.parse(answers)
      this.loadingService.setLoading(false);
      this.dialog.open(SuccesMessageComponent, {
        data: {
          answers: result.answersCount,
          correctAnswers: result.correctAnswers,
          pass: result.pass,
          testId:this.testId
        }
      });
    })
  }
}
