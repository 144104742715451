import { Component } from '@angular/core';
import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GetInfoService } from '../../../shared/services/get-info.service';
import { WriteDataService } from '../../../shared/services/write-data.service';
import { HelpersService } from '../../../shared/services/helpers.service';
import { LoaderService } from '../../../shared/services/loading/loader.service'
import { Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';


@Component({
  selector: 'app-lessons',
  templateUrl: './lessons.component.html',
  styleUrls: ['./lessons.component.scss']
})
export class LessonsComponent implements OnInit {
  apiLoaded = false;
  lesson: any;
  id!: string;
  tasks: string[] = [];
  links: any[] = [];
  isRender = false;
  studentInfo!: any;
  navBtnLink: string = "";
  navBtnMessage: string = "";
  labelIconUrl: string = "../../../../assets/images/fail-icon.png";
  labelMessage: string = "Для того, аби відкрити наступну лекцію, вам необхідно успішно пройти тест."
  isLabelRender: boolean = false;
  isLabelDone: boolean = false;
  lessonTestDepencies: { [key: string]: string; } = {
    market: "first_test",
    strategies: "second_test",
    dump: "third_test",
    pump: "fourth_test"
  }
  buttonLinks: { [key: string]: any; } = {
    company: "dashboard/lesson/market",
    marketfalse: "dashboard/lesson/moonbot",
    markettrue: "dashboard/lesson/moonbot",
    moonbot: "dashboard/lesson/strategies",
    strategiesfalse: "dashboard/test/second_test",
    strategiestrue: "dashboard/lesson/dump",
    dumpfalse: "dashboard/test/third_test",
    dumptrue: "dashboard/lesson/pump",
    pumpfalse: "dashboard/test/fourth_test",
    pumptrue: "dashboard/lesson/discord",
    discord: "dashboard/lesson/active_market",
    active_market: "dashboard/profile"
  }

  //adaptives
  videoWidth = 669;
  videoHeight = 376.3;
  constructor(
    private route: ActivatedRoute,
    private getInfo: GetInfoService,
    private loadService: LoaderService,
    private writeData: WriteDataService,
    public helpers: HelpersService,
    public router: Router,
    private responsive: BreakpointObserver
  ) { }
  ngOnInit(): void {
    this.helpers.setLastVisitedPage(this.router.url)
    if (!this.apiLoaded) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(tag);
      this.apiLoaded = true;
    }
    this.getLesson()
    this.responsive.observe(['(max-width: 767.98px)', '(min-width: 768px and max-width: 1022.98px)', '(min-width: 1023px)'])
      .subscribe(() => this.breakpointChanged()
      );
  }

  private breakpointChanged() {
    if (this.responsive.isMatched('(max-width: 767.98px)')) {
      this.videoWidth = 328;
      this.videoHeight = 184.5;
    }
    if (this.responsive.isMatched('(max-width: 1022.98px)') && this.responsive.isMatched('(min-width:768px)')) {
      this.videoWidth = 669;
      this.videoHeight = 376.3;
    }
    if (this.responsive.isMatched('(min-width: 1023px)')) {
      this.videoWidth = 669;
      this.videoHeight = 376.3;
    }
  }

  getLesson = async () => {

    this.route.params
      .subscribe(async (params: any) => {
        this.helpers.setLastVisitedPage(this.router.url)
        this.id = (params['id']).trim();
        this.lesson = await this.getInfo.getLesson(this.id)
        this.generateNavBtn(this.id)
        if (this.lesson) {
          this.tasks = this.lesson.tasks.split('.').reduce((acc: any, task: any, index: number, array: any[]) => { if ((index) % 2 == 0) { return [...acc, (task.trim() + "." + array[index + 1])] } else return acc }, [])
          if (this.lesson.links !== "") {
            let arr = this.lesson.links.split(';').filter((link: string) => link !== "")
            let newArr = arr.map((link: string) => link.split("~"))
            let arrn = newArr.reduce((acc: any, link: string) => [...acc, { n: link[0], link: link[1].trim() }], [])
            this.links = arrn;
          }
          this.isRender = true
        }
      })
  }
  async generateNavBtn(pageId: string) {
    let studentId = localStorage.getItem('uid')
    if (studentId) {
      (await this.getInfo.getStudentInfo(studentId)).subscribe(res => {
        this.studentInfo = res;
        if (pageId == "market" || pageId == "strategies" || pageId == "dump" || pageId == "pump") {
          this.navBtnLink = this.buttonLinks[pageId + this.studentInfo[this.lessonTestDepencies[pageId]]]
          this.isLabelRender = true;
          if (this.studentInfo[this.lessonTestDepencies[pageId]] || pageId == "market") {
            this.isLabelDone = true;
            this.navBtnMessage = "Наступна лекція";
            this.labelIconUrl = "../../../../assets/images/success-icon.png";
            this.labelMessage = "Пройдено успішно, доступ до наступної лекціі відкрито!"
          }
          else {
            this.isLabelDone = false;
            this.labelIconUrl = "../../../../assets/images/fail-icon.png";
            this.labelMessage = "Для того, аби відкрити наступну лекцію, вам необхідно успішно пройти тест."
            this.navBtnMessage = "Пройти тест"
          }
        }
        else {
          this.isLabelRender = false;
          this.navBtnLink = this.buttonLinks[pageId]
          this.navBtnMessage = "Наступна лекція"
        }
        if (pageId == "active_market") {
          if (this.studentInfo.profileFulled) {
            this.navBtnMessage = "Перейти у дискорд"
          }
          else {
            this.isLabelRender = true;
            this.labelIconUrl = "../../../../assets/images/fail-icon.png";
            this.labelMessage = "Щоб перейти у діскорд, будь ласка заповніть профіль"
            this.navBtnMessage = "Заповнити профіль"
          }
        }
      })
    }
  }
  async navBtnClick() {
    if (this.id == "active_market") {
      let windowReference = window.open();
      if (this.studentInfo.profileFulled) {
        this.loadService.setLoading(true);
        (await this.getInfo.getInviteLink()).subscribe(link => {
          if(link){
            this.loadService.setLoading(false);
            windowReference!.location = link;
            // window.open(link, "_blank");
          }
        })
        return
      }
      else {
        this.router.navigate([this.navBtnLink])
      }
    }

    if (this.id == "market") {
      let id = localStorage.getItem('uid')
      if (id) {
        this.writeData.changeActivityMarker(id)
      }
      this.router.navigate([this.navBtnLink])
      return
    }
    this.router.navigate([this.navBtnLink])
  }
}
