import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-hello-page',
  templateUrl: './hello-page.component.html',
  styleUrls: ['./hello-page.component.scss']
})
export class HelloPageComponent implements OnInit {

  constructor(private router:Router) { }

  ngOnInit(): void {
  }
  goToProfile(){
    this.router.navigate(['dashboard/profile'])
  }
}
