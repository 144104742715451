import { NgModule } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { YouTubePlayerModule } from '@angular/youtube-player';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/home/login/login.component';
import { RegistrationComponent } from './pages/home/registration/registration.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ProfileComponent } from './pages/dashboard/profile/profile.component';
import { LessonsComponent } from './pages/dashboard/lessons/lessons.component';
import { TestsComponent } from './pages/dashboard/tests/tests.component';
import { HelloPageComponent } from './pages/dashboard/hello-page/hello-page.component';
import { SuccesMessageComponent } from './pages/dashboard/tests/succes-message/succes-message.component';
import { PrivatePolicyComponent } from './pages/private-policy/private-policy/private-policy.component';
import { TermsConditionsComponent } from './pages/private-policy/terms-conditions/terms-conditions.component';
import { SpinnerComponent } from './shared/services/loading/spinner/spinner.component';


import { AuthService } from "./shared/services/auth.service"
import { GetInfoService } from './shared/services/get-info.service';
import { WriteDataService } from './shared/services/write-data.service';
import { HelpersService } from './shared/services/helpers.service';
import { LoaderService } from './shared/services/loading/loader.service';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDividerModule } from '@angular/material/divider';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';


import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireAnalyticsModule } from "@angular/fire/compat/analytics";
import { AngularFireRemoteConfigModule } from '@angular/fire/compat/remote-config';
import { environment } from '../environments/environment';

import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { provideFunctions, getFunctions } from '@angular/fire/functions';
import { provideMessaging, getMessaging } from '@angular/fire/messaging';
import { provideStorage, getStorage } from '@angular/fire/storage';


import { LoadingInterceptor } from './shared/services/loading/loading.interceptor';
import { RoadmapComponent } from './pages/home/roadmap/roadmap.component';
import { TgIntegrationComponent } from './pages/home/tg-integration/tg-integration.component';
import { FeedbacksComponent } from './pages/home/feedbacks/feedbacks.component';

export const options: Partial<null | IConfig> | (() => Partial<IConfig>) = null;
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    RegistrationComponent,
    DashboardComponent,
    ProfileComponent,
    LessonsComponent,
    TestsComponent,
    HelloPageComponent,
    SuccesMessageComponent,
    PrivatePolicyComponent,
    TermsConditionsComponent,
    SpinnerComponent,
    RoadmapComponent,
    TgIntegrationComponent,
    FeedbacksComponent
  ],
  entryComponents: [SuccesMessageComponent],
  imports: [
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAnalyticsModule,
    AngularFireRemoteConfigModule,
    NgxMaskModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatDividerModule,
    ScrollingModule,
    SlickCarouselModule,
    MatSidenavModule,
    MatCheckboxModule,
    FormsModule,
    MatExpansionModule,
    MatListModule,
    MatRadioModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    YouTubePlayerModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    provideFunctions(() => getFunctions()),
    provideMessaging(() => getMessaging()),
    provideStorage(() => getStorage())
  ],
  providers: [
    AuthService,
    GetInfoService,
    WriteDataService,
    HelpersService,
    LoaderService,
    {
      provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
