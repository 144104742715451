import { Component, OnInit, Input } from '@angular/core';
import { GetInfoService } from '../../../shared/services/get-info.service'

@Component({
  selector: 'app-feedbacks',
  templateUrl: './feedbacks.component.html',
  styleUrls: ['./feedbacks.component.scss']
})
export class FeedbacksComponent implements OnInit {

  constructor(private getInfo: GetInfoService) { }
  @Input() isMobile!: boolean
  feedbacks!: any;
  slideConfig = {
    "slidesToShow": 1, "slidesToScroll": 1, dots: true, arrows: false, infinite: true, autoplay: true, autoplaySpeed: 10000,
  };
  ngOnInit(): void {
    this.getInfo.getFeedbacks().subscribe((res: any) => {
      this.feedbacks = res
    
    })
  }
}
