import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../shared/services/auth.service';
import { Router } from '@angular/router';
import { LoaderService } from '../../../shared/services/loading/loader.service'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  form: FormGroup = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(6)]]
  });
  errorCode: any = "";
  ERRORS: { [key: string]: string; } = {
    "auth/user-not-found": "Такого користувача не існує",
    "auth/invalid-email": "Будь ласка введіть корректний email",
    "auth/email-already-exists": "Ця пошта вже зареєстрована",
    "auth/wrong-password": "Невірний пароль"
  }
  constructor(
    private fb: FormBuilder,
    public authService: AuthService,
    public router: Router,
    public loadingService: LoaderService
  ) { }
  onSubmit() { }
  ngOnInit(): void {
  }

  signIn = async (email: string, password: string) => {
    this.loadingService.setLoading(true);
    (await this.authService.SignIn(email, password))
      .subscribe(
        {
          next: (res) => {
            res.then(async res => {
              await this.authService.signInCustom(res).then(() => {
                const link = localStorage.getItem('url') || "dashboard";
                this.router.navigate([link]);
                this.loadingService.setLoading(false);
              })
            });
          },
          error: (err: any) => {
            this.loadingService.setLoading(false);
            this.errorCode = err.details.code;
            if (err.details.code == "auth/wrong-password") {
              this.form.get('password')?.setErrors({ invalid: true });
            }
            else {
              this.form.get('email')?.setErrors({ invalid: true });
            }
          }
        }
      )
  }
  signUpG = async () => {
    this.loadingService.setLoading(true);
    (await this.authService.GoogleAuth()).subscribe({
      next: (res) => {
        this.authService.signInCustom(res).then(() => {
          const link = localStorage.getItem('url') || "dashboard"
          this.router.navigate([link])
          this.loadingService.setLoading(false);
        })
      },
      error: (err: any) => {
        this.loadingService.setLoading(false);
      }
    })

  }

}
