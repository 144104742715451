import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
@Component({
  selector: 'app-succes-message',
  templateUrl: './succes-message.component.html',
  styleUrls: ['./succes-message.component.scss']
})
export class SuccesMessageComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public router: Router) { }
  links: any = {
    first_testtrue: "dashboard/lesson/moonbot",
    first_testfalse: "dashboard/lesson/company",
    second_testtrue: "dashboard/lesson/dump",
    second_testfalse: "dashboard/lesson/moonbot",
    third_testtrue: "dashboard/lesson/pump",
    third_testfalse: "dashboard/lesson/dump",
    fourth_testtrue: "dashboard/lesson/discord",
    fourth_testfalse: "dashboard/lesson/pump",
  };
  buttonText: string = "";
  iconUrl: string = "";
  message: string = "";
  isUndone: boolean = false;
  ngOnInit(): void {
    if (this.data.pass) {
      this.iconUrl = "../../../../../assets/images/success-icon.png"
      this.message = "Тест пройдено успішно, доступ до наступного етапу відкрито!"
      this.buttonText = "Перейти на нову лекцію"
      this.isUndone=false;
    }
    else {
      this.iconUrl = "../../../../../assets/images/fail-icon.png"
      this.message = "Тест не пройдено!"
      this.buttonText = "Повернутися до лекції"
      this.isUndone=true;
    }
  }
  nextButtonClick(){
    const link=this.links[this.data.testId + this.data.pass]
    this.router.navigate([link])
  }
}
