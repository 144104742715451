<div class="first-screen">
    <div class="container col-12">
        <div class="image-container col-12">
            <img src="./assets/images/logo.png" class="logo" alt="">
            <a *ngIf="!isAuthenticated" routerLink="login">
                <img src="../../../assets/images/log-in.png" alt="">
                Вхід
            </a>
            <a *ngIf="isAuthenticated" mat-button routerLink="dashboard">
                <img *ngIf="isMobile" src="../../../assets/images/log-in.png" alt="">
                <div *ngIf="!isMobile">Навчальна платформа</div>
            </a>
        </div>
        <div class="text-container">
            <div *ngIf="showText">
                <div *ngIf="!showNew" class="first-header">Досягай фінансової свободи з нами</div>
                <div *ngIf="showNew" class="first-header">Стати трейдером <br> через 3,2,1...</div>
            </div>
            <div class="text">Опануй нову професію трейдера-аналітика разом з Flowspot: безкоштовне навчання та
                підтримка на кожному етапі твого розвитку</div>
            <a routerLink="/registration" (click)="vis2reg()">
                <button mat-flat-button color="primary" class="button">Зареєструватися</button>
            </a>
        </div>
        <div *ngIf="isLarge" class="finger-1"></div>
    </div>
    <div class="bg-img"></div>
    <div *ngIf="isLarge" class="finger-2"></div>
    <div class="devider"></div>
</div>
<div class="container">
    <div class="second-sreen col-12">
        <div class="header">Переваги</div>
        <div class="advatages-container">
            <div class="first-row">
                <mat-card class="card first-card col-5">
                    <mat-card-header>
                        <mat-card-title class="title">
                            1
                        </mat-card-title>
                        <mat-card-subtitle class="subtitle">
                            Гнучкий графік:
                        </mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content class="content">
                        працюй у будь-який зручний час, незалежно від місця;
                    </mat-card-content>
                    <img src="../../../assets/images/second-section1.png" alt="">
                </mat-card>
                <mat-card class="card second-card">
                    <mat-card-header>
                        <mat-card-title class="title">
                            2
                        </mat-card-title>
                        <mat-card-subtitle class="subtitle">
                            Відсутність фінансової стелі:
                        </mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content class="content">
                        ти сам керуєш рівнем свого заробітку, який залежить від кількості укладених угод та отримуєш
                        100% прибутку;
                    </mat-card-content>
                    <img src="../../../assets/images/second-section2.png" alt="">
                </mat-card>
            </div>
            <div class="second-row">
                <mat-card class="card third-card">
                    <mat-card-header>
                        <mat-card-title class="title">
                            3
                        </mat-card-title>
                        <mat-card-subtitle class="subtitle">
                            Підтримка та навчання:
                        </mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content class="content">
                        ми забезпечуємо безкоштовне навчання та ментора, який підтримуватиме і допомагатиме у прийнятті
                        фінансових рішень;
                    </mat-card-content>
                    <img src="../../../assets/images/second-section3.png" alt="">
                </mat-card>
                <mat-card class="card fourth-card">
                    <mat-card-header>
                        <mat-card-subtitle class="subtitle">
                            Розвивайся та змінюй своє майбутнє разом з Flowspot
                        </mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content class="content">
                        <a routerLink="/registration" (click)="vis2reg()">
                            <button mat-stroked-button color="accent" class="button">
                                Приєднатися до Flowspot
                            </button>
                        </a>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</div>
<div class="third-screen">
    <div class="container">
        <div class="coloumns-container">
            <div class="first-coloumn">
                <div class="header">Детальніше про проект
                    Flowspot -
                </div>

                <img src="../../../assets/images/about-img.png" alt="">

            </div>
            <div class="second-coloumn">
                <mat-accordion>
                    <mat-expansion-panel class="first-box" hideToggle [disabled]="firstPanelState"
                        [expanded]="firstPanelState == true" (opened)="firstPanelState = true; accordeonChange()"
                        (closed)="firstPanelState = false; accordeonChange()">
                        <mat-expansion-panel-header>
                            <mat-panel-title class="header">
                                Це проп-трейдингова компанія
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="text">
                            Flowspot об'єднує трейдинг та освіту. Наша мета - надати трейдерам усі можливості для
                            заробітку на ринку криптовалютних активів та забезпечити всіма інструментами професійного
                            зростання
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="second-box" hideToggle
                        (opened)="secondPanelState = true; accordeonChange()"
                        (closed)="secondPanelState = false; accordeonChange()">
                        <mat-expansion-panel-header>
                            <mat-panel-title class="header">
                                Надає можливість працювати з професіоналами
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="text second-text">
                            Flowspot пропонує можливість працювати з нашими професійними трейдерами-аналітиками. Ми
                            надаємо
                            безкоштовне навчання та підтримку на кожному етапі твого розвитку, щоб ви були впевнені в
                            своїх фінансових рішеннях.
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="third-box" hideToggle
                        (opened)="thirdPanelState = true; accordeonChange()"
                        (closed)="thirdPanelState = false; accordeonChange()">
                        <mat-expansion-panel-header>
                            <mat-panel-title class="header">
                                Заробляємо разом з вами, а не на вас
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="text">
                            Одним з головних принципів Flowspot є те, що ми заробляємо разом з вами, а не на вас. Наш
                            успіх залежить від вашого успіху. Ми дбаємо про те, щоб наші трейдери отримували
                            максимальний прибуток, і не беремо додаткову комісію.
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
    </div>

    <a *ngIf='this.thirdBlockShowButton' routerLink="/registration" (click)="vis2reg()">
        <div class="main-div alternative">
            <button mat-flat-button color="primary" class="button">Розпочати навчання</button>
        </div>
    </a>
</div>
<app-tg-integration></app-tg-integration>
<app-roadmap></app-roadmap>
<app-feedbacks [isMobile]="isMobile"></app-feedbacks>
<div class="fifth-screen">
    <div class="header">
        Розпочни свій шлях до фінансової свободи з Flowspot!
    </div>
    <div class="main-div alternative">
        <a routerLink="/registration" (click)="vis2reg()">
            <button mat-flat-button color="primary" class="button">Зареєструватися</button>
        </a>
    </div>

    <div class="footer container">
        <div class="line"></div>
        <div class="footer-links">
            <div class="policy">
                <a routerLink="/terms&conditions">Terms & Conditions</a>
                <a routerLink="/privacy_policy">Privacy Policy</a>
            </div>
            <div class="social">
                <a href="https://www.instagram.com/flowspot.trade/" target="_blank">
                    <img src="../../../assets/images/inst-logo.png" alt="">
                </a>
                <a href="https://t.me/flowspot_trade" target="_blank">
                    <img src="../../../assets/images/tg-logo.png" alt="">
                </a>
            </div>
        </div>
    </div>
</div>