<div class="roadmap">
    <div class="container">
        <div class="header">План роботи</div>
        <div class="stages-container">
            <div *ngFor="let stage of stages, let i = index" class="stage-card">
                <div class="stage-header" [style.color]="stage.color" id="stage{{i+1}}">
                    <div class="stage-icon" [style.border]="'1px dashed '+ stage.color">
                        <img src="{{stage.icon}}" alt="">
                    </div>
                    Крок {{i+1}}
                </div>
                <div class="stage-content">
                    <div class="stage-subheader">{{stage.header}}</div>
                    <div class="stage-text">{{stage.text}}</div>
                    <div class="stage-timeline"><b>{{stage.timeline}}</b></div>
                </div>
            </div>
        </div>
    </div>
</div>