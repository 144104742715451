import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../shared/services/auth.service';
import { Router } from '@angular/router';
import { LoaderService } from '../../../shared/services/loading/loader.service'
import { AngularFireAnalytics } from '@angular/fire//compat/analytics';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

  form: FormGroup = this.fb.group({
    firstName: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(6)]]
  });
  errorCode: any = "";
  ERRORS: { [key: string]: string; } = {
    "auth/user-not-found": "Такого користувача не існує",
    "auth/invalid-email": "Будь ласка введіть корректний email",
    "auth/email-already-exists": "Ця пошта вже зареєстрована",
    "auth/wrong-password": "Невірний пароль"
  }

  constructor(
    private fb: FormBuilder,
    public authService: AuthService,
    public router: Router,
    public loadingService: LoaderService,
    private analytics: AngularFireAnalytics
  ) { }
  abTestVariant: string | null = "";
  ngOnInit(): void {  
    this.abTestVariant = localStorage.getItem('test')
  }
  onSubmit() {

  }
  signUpWithEmailPassword = (email: string, password: string, firstName: string) => {
    this.loadingService.setLoading(true);
    this.authService.SignUp(email, password, firstName).subscribe({
      next: async (res) => {
        await this.authService.signInCustom(res).then(() => {
          this.loadingService.setLoading(false);
          this.router.navigate(['dashboard'])
          this.setRegEvent();
        })
      },
      error: (error) => {

        this.loadingService.setLoading(false);
        this.errorCode = error.details.code;
        if (error.details.code == "auth/wrong-password") {


          this.form.get('password')?.setErrors({ invalid: true });
        }
        else {
          this.form.get('email')?.setErrors({ invalid: true });
        }

      }
    }


    )
  }
  signUpG = async () => {
    this.loadingService.setLoading(true);
    (await this.authService.GoogleAuth()).subscribe(
      (res) => {
        this.loadingService.setLoading(false);
        const link = localStorage.getItem('url') || "dashboard"
        this.router.navigate([link])
        this.setRegEvent()
      },
      (error) => {
        this.loadingService.setLoading(false);
        if (error.details.code == "auth/wrong-password") {
          this.form.get('password')?.setErrors({ invalid: true });
        }
        else {
          this.form.get('email')?.setErrors({ invalid: true });
        }
      }
    )
  }

  async setRegEvent(){
    this.analytics.logEvent('reg_event', {
      variant: this.abTestVariant,
    });
    localStorage.removeItem('test')
  }
}
