import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import * as auth from 'firebase/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { getAuth, signInWithCustomToken, setPersistence, browserSessionPersistence } from 'firebase/auth';
import { tap, map, concatMap } from "rxjs/operators";

import { Router } from '@angular/router';

import { LoaderService } from './loading/loader.service'
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  userData: any;
  isNewUser: boolean = false;
  constructor(
    public afAuth: AngularFireAuth,
    public router: Router,
    private fns: AngularFireFunctions,
    private loadingService: LoaderService
  ) {
    this.authStatusListener();
    this.uidAdded();
  }
  currentUser: any = '';
  uid: any = '';
  private authStatusSub = new BehaviorSubject(this.currentUser);
  currentAuthStatus = this.authStatusSub.asObservable();
  private localStorageChange = new BehaviorSubject(this.uid)
  storageChange = this.localStorageChange.asObservable();
  NewUser = new BehaviorSubject(this.isNewUser);
  pageSown = this.NewUser.asObservable()

  async signInCustom(token: string) {
    const auth = getAuth();
    return await signInWithCustomToken(auth, token)
  }

  SignUp(email: string, password: string, firstName: string) {
    const signUpWithEmailPassword = this.fns.httpsCallable('signUpWithEmailPassword');
    return signUpWithEmailPassword({ email, password, firstName })
      .pipe(tap(res => {
        this.afAuth.user.subscribe((user) => {
          if (user) {
            localStorage.setItem('uid', user.uid);
            this.uidAdded()
          }
        });
        return res
      }))
  }

  async GoogleAuth() {
    const fauth = getAuth();
    const signInG = this.fns.httpsCallable('signInG');
    let isNewUser: any = false;

    try {
     await this.afAuth.signInWithPopup(new auth.GoogleAuthProvider())
        .then((res) => {
          isNewUser = res.additionalUserInfo!.isNewUser;
          this.NewUser.next(isNewUser);
        })
    }
    catch {
      this.loadingService.setLoading(false);
    }
    return signInG({ isNewUser }).pipe(tap(async res => {
      await this.afAuth.signOut();
      this.afAuth.user.subscribe(user => {
        if (user) {
          localStorage.setItem("uid", user.uid)
          this.uidAdded()
        }
      })
      return res
    }))
  }

  async SignIn(email: string, password: string) {
    const signIn = this.fns.httpsCallable('signIn');
    return signIn({ email, password })
      .pipe(map(async res => {
        this.afAuth.user.subscribe((user) => {
          if (user) {
            localStorage.setItem('uid', user.uid);
            this.uidAdded()
          }
        });
        return res
      }))
  }
  async logOut() {
    await this.afAuth.signOut();
    localStorage.removeItem('uid');
  }

  async authStatusListener() {
    await this.afAuth.onAuthStateChanged((credential) => {

      if (credential) {
        this.authStatusSub.next(credential);

      }
      else {
        this.authStatusSub.next(null);
      }
    })
  }
  uidAdded() {
    let res = localStorage.getItem('uid');
    if (res === null) {
      this.localStorageChange.next(null);
    }
    else {
      this.localStorageChange.next(res)
    }
  }
}
