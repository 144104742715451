<div class="profile-container">
  <form *ngIf="isRender" [formGroup]="form" class="form" (ngSubmit)="onSubmit()">
    <div class="user-photo-container">
      <img class="user-photo" src="{{photoUrl}}" alt="">
      <div class="photo-text" (click)="inputField.click()">Редагувати фотографію профілю</div>
      <input #inputField type="file" accept="image/*" (change)="uploadImage($event)" hidden>
    </div>
    <div *ngIf="user" class="first-text">{{user.email}}</div>
    <mat-form-field appearance="outline">
      <mat-label>Ім'я</mat-label>
      <input matInput formControlName="firstName" name="firstName" [(ngModel)]="user.firstName" #userFirstName />
      <mat-error *ngIf="this.form.controls['firstName'].errors?.['required']">Введіть значення</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Прізвище</mat-label>
      <input matInput formControlName="lastName" name="lastName" [(ngModel)]="user.lastName" #userLastName />
      <mat-error *ngIf="this.form.controls['firstName'].errors?.['required']">Введіть значення</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Телефон(+380)</mat-label>
      <input matInput formControlName="phone" [(ngModel)]="user.phone" type="text" prefix="+38 " mask="(000) 000 00 00"
        name="phone" #userPhone />
      <mat-error *ngIf="this.form.controls['phone'].errors?.['required']">Введіть значення</mat-error>
      <mat-error *ngIf="this.form.controls['phone'].errors">Введіть корекний номер</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Дата народження</mat-label>
      <input mask="00/00/0000" matInput placeholder="ДД/ММ/РРРР" [(ngModel)]="user.birthDate"
        formControlName="birthDate" name="birthDate" type="birthDate" #userBirthDate />
      <mat-error *ngIf="this.form.controls['birthDate'].errors?.['required']">Введіть значення</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Нікнейм Discord</mat-label>
      <input matInput formControlName="discord" [(ngModel)]="user.discord" name="discord" type="discord" #userDiscord />
      <mat-error *ngIf="this.form.controls['discord'].errors?.['required']">Введіть значення</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Місто проживання</mat-label>
      <input matInput formControlName="city" [(ngModel)]="user.city" name="city" #userCity />
      <mat-error *ngIf="this.form.controls['city'].errors?.['required']">Введіть значення</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <!-- [ngModelOptions]="{standalone: true}" -->
      <mat-label>Звідки ти дізнався про Flowspot</mat-label>
      <mat-select [(ngModel)]="user.interrogation" formControlName="interrogation" #userInterrogation>
        <mat-option value="Google">Реклама в Google</mat-option>
        <mat-option value="Facebook">Реклама в Facebook</mat-option>
        <mat-option value="Instagram">Реклама в Instagram</mat-option>
        <mat-option value="Profile Instagram">З інстаграм акаунту Flowspot</mat-option>
        <mat-option value="YouTube">З YouTube</mat-option>
        <mat-option value="work.ua">Оголошення Work.ua</mat-option>
        <mat-option value="rabota.ua">Оголошення Rabota.ua</mat-option>
        <mat-option value="Telegram">Telegram</mat-option>
        <mat-option value="Friends">Рекомендація друзів / знайомих</mat-option>
        <mat-option value="Other">Інше</mat-option>
      </mat-select>
    </mat-form-field>
    <div class="text-center mt-4">
      <span matTooltip="Будь ласка, заповніть усі поля вище." matTooltipPosition="above" matTooltipClass="bg-danger"
        [matTooltipDisabled]="form.valid">
        <button mat-raised-button color="primary" class="save-btn" type="submit" [disabled]="form.invalid"
          (click)="addData(userFirstName.value,userLastName.value,userPhone.value,userBirthDate.value,userCity.value,userDiscord.value,userInterrogation.value)">
          Зберегти
        </button>
      </span>
      <button *ngIf="isShowDiscordBtn" mat-raised-button color="primary" class="discord-nav-btn"
        (click)="btnClick()">Перейти у Discord </button>
    </div>
  </form>
</div>