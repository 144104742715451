<div class="container">
    <div class="go-back-btn" (click)="this.backClicked()">
        <img src="../../../assets/images/go-back.png" alt="">
        Назад
    </div>
    <p></p>
    <p></p>
    <p></p>
    <div class="header">
        <b>
            ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ<br>
            FLOWSPOT
        </b>
    </div>
    <p></p>
    <p></p>
    <div class="text">
        <p>
            При використанні наших послуг ми обробляємо персональні дані наших клієнтів ("ви", "ваш" або "Клієнт"). Ця
            Політика конфіденційності («Політика») надає вам інформацію про те, як ми обробляємо ваші дані.
        </p>
        <p>У цій Політиці конфіденційності ви знайдете інформацію щодо наступного:</p>
        <p class="sub">1. Хто є контролером ваших персональних даних і до кого звертатися?</p>
        <p class="sub">2. Яку інформацію ми збираємо про вас і як ми її отримуємо?</p>
        <p class="sub">3. Як ми використовуємо ваші персональні дані?</p>
        <p class="sub">4. Як ми зберігаємо персональні дані і як їх захищаємо?</p>
        <p class="sub">5. Як довго ми обробляємо ваші дані?</p>
        <p class="sub">6. Чи передаємо ми ваші дані третім особам (сторонам)?</p>
        <p class="sub">7. Які у вас є права і як ви можете їх здійснити?</p>
    </div>
    <p></p>
    <div class="subheader">1. ХТО Є КОНТРОЛЕРОМ ВАШИХ ПЕРСОНАЛЬНИХ ДАНИХ І ДО КОГО ЗВЕРТАТИСЯ?</div>
    <div class="text">
        <p>
            Контролером персональних даних є LIMITED LIABILITY COMPANY FLOWSPOT (LLC FLOWSPOT), зареєстрована за адресою
            Україна, 79005, Львівська область, місто Львів, вулиця Братів Рогатинців, будинок 17, офіс 1, код ЄДРПОУ -
            45175889 (надалі - «ми», «наша», «наша компанія» або «контролер»).
        </p>
        <p>
            Ви можете зв'язатися з FLOWSPOT за адресою info@flowspot.trade щодо будь-яких питань та використання прав,
            що стосуються обробки ваших персональних даних.
        </p>
    </div>
    <div class="subheader">2. ЯКУ ІНФОРМАЦІЮ МИ ЗБИРАЄМО ПРО ВАС І ЯК МИ ЇЇ ОТРИМУЄМО?</div>
    <div class="text">
        <p>
            У цьому розділі ви знайдете загальну інформацію про те, яку персональну інформацію ми збираємо і як ми її
            отримуємо. Докладна інформація про цілі, для яких ми обробляємо персональні дані, наведена у розділі 3 цієї
            Політики. Всі дані, за допомогою яких ми можемо прямо або опосередковано ідентифікувати вас, або які
            стосуються вас, вважаються персональними даними.
        </p>
        <p>
            Ми отримуємо більшість даних про вас безпосередньо від вас у зв'язку з використанням наших послуг. Це
            включає зокрема:
        </p>
        <p>
            Дані, які ви надаєте нам самостійно, особливо при реєстрації на веб-сайті, введенні інформації у свій
            обліковий запис, замовленні послуг, участі в наших подіях та проектах, або при спілкуванні з нами через
            службу підтримки клієнтів або соціальні мережі. Це ідентифікаційні та контактні дані, зокрема ім'я,
            прізвище, номер телефону, адреса електронної пошти, поштова адреса, дата народження, ім'я користувача та
            пароль, ідентифікаційний номер платника податків; та інші дані, такі як записи комунікації між вами та нашою
            компанією або деталі гарантійних вимог.
        </p>
        <p>
            Дані, які ми автоматично збираємо, коли ви користуєтеся нашими послугами. Це дані про ваш пристрій (такі як
            IP-адреса, тип пристрою, операційна система, використовуваний браузер, постачальник підключення); дані про
            використання веб-сайту (такі як дата, час і тривалість візиту, країна, з якої ви відвідуєте веб-сайт,
            історія перегляду веб-сайту) та дані про використання послуг (такі як інформація про вхід та вихід з
            облікового запису, налаштування вашого облікового запису, значення вашого умовного капіталу, валюта вашого
            облікового запису, бізнес-стратегія).
        </p>
        <p>
            Деякі дані про вас надаються нам третіми сторонами, такими як оператори соціальних мереж. Це включає:
        </p>
        <p>
            Дані з соціальних мереж (ім'я користувача, аватар, адреса електронної пошти, пов'язана з обліковим записом у
            соціальній мережі). Для отримання інформації про те, як треті сторони обробляють вашу персональну інформацію
            для власних цілей, будь ласка, зверніться до політик конфіденційності цих сторін.
        </p>
    </div>
    <div class="subheader">3. ЯК МИ ВИКОРИСТОВУЄМО ВАШІ ПЕРСОНАЛЬНІ ДАНІ?</div>
    <div class="text">
        <p>
            Ми використовуємо персональні дані для наступних цілей:
        </p>
        <p>
            <b>
                Реєстрація та обліковий запис користувача
            </b>
        </p>
        <p>
            Для використання послуг вам потрібно зареєструватися на веб-сайті та створити свій обліковий запис, і для
            цього ми обробляємо ваші персональні дані.
        </p>
        <p>
            Персональні дані: дані ідентифікації та контактні дані; ми також можемо обробляти дані, що стосуються
            налаштувань вашого облікового запису.
        </p>
        <p>
            Правова підстава для обробки: Обробка необхідна для виконання договору, і надання цих персональних даних є
            обов'язковим, оскільки ми не можемо зареєструвати ваш обліковий запис користувача без них.
        </p>
        <p>
            <b>
                Надання послуг та виконання прав та обов'язків за договором між нами та клієнтом
            </b>
        </p>
        <p>
            Для надання вам наших послуг, зокрема, надання вам відповідного доступу, інструментів і підтримки, а також
            здійснення угод, нам потрібно обробляти вашу персональну інформацію. Для цих цілей застосовуються такі
            положення:
        </p>
        <p>
            Персональні дані: дані ідентифікації та контактні дані, дані про використання послуг, дані про симульовані
            угоди та, якщо ви вирішите пов'язати свої облікові записи в соціальних мережах з обліковим записом на
            веб-сайті, також дані з соціальних мереж.
        </p>
        <p>
            Правова підстава для обробки: Обробка необхідна для виконання договору, на підставі якого ми надаємо наші
            послуги, або на підставі наших законних інтересів. Після припинення надання вам послуг, ми обробляємо
            персональну інформацію протягом обмеженого періоду. Така обробка необхідна для здійснення та захисту наших
            прав на підставі наших законних інтересів.
        </p>
        <p>
            <b>
                Маркетингові комунікації щодо наших послуг і продуктів або подій, в яких ми беремо участь
            </b>
        </p>
        <p>
            Якщо ви є нашим поточним або колишнім клієнтом, час від часу ми можемо надсилати вам маркетингові
            комунікації, що стосуються наших послуг. Ми також можемо надсилати вам комерційні комунікації в тому
            випадку, якщо ви дали нам згоду. Кожне з надісланих електронних листів буде позначене як комерційна
            комунікація, і в кожному з них буде посилання, за допомогою якого ви зможете легко відписатися від
            комерційних комунікацій. Для цих цілей застосовуються такі положення:
        </p>
        <p>
            Персональні дані: дані ідентифікації та контактні дані.
        </p>
        <p>
            Правова підстава для обробки: Якщо ми надали вам наші послуги, час від часу ми можемо надсилати вам
            маркетингові комунікації на підставі наших законних інтересів, зокрема з метою зберігання зв'язку з нашими
            клієнтами. Якщо ви дали нам згоду на обробку ваших даних в маркетингових цілях, тоді ми обробляємо ваші дані
            на цій підставі.
        </p>
        <p>
            <b>
                Підтримка клієнтів та комунікація
            </b>
        </p>
        <p>
            Якщо ви використовуєте наші послуги підтримки клієнтів, ми будемо обробляти вашу персональну інформацію. Для
            цих цілей застосовуються такі положення:
        </p>
        <p>
            Персональні дані: дані ідентифікації, контактні дані, інші дані та дані з соціальних мереж, якщо ви
            звертаєтеся до нас через соціальні мережі.
        </p>
        <p>
            Правова підстава для обробки: Обробка здійснюється на підставі наших законних інтересів, зокрема в
            забезпеченні задоволення клієнтів та підтримці використання наших послуг.
        </p>
        <p>
            <b>
                Покращення послуг
            </b>
        </p>
        <p>
            Якщо ви використовуєте деякі з наших послуг, ми обробляємо вашу персональну інформацію з метою розвитку,
            тестування та поліпшення послуг та покращення їх безпеки. Для цих цілей застосовуються такі положення:
        </p>
        <p>
            Персональні дані: дані ідентифікації та контактні дані, дані про ваш пристрій, дані про використання
            веб-сайту, дані про використання послуг і дані про симульовані угоди.
        </p>
        <p>
            Правова підстава для обробки: Обробка здійснюється на підставі наших законних інтересів, зокрема в
            поліпшенні наших послуг.
        </p>
        <p>
            <b>
                Аналітика
            </b>
        </p>
        <p>
            Для отримання інформації про те, як використовується наш веб-сайт та наші послуги, ми аналізуємо поведінку
            клієнтів. Для цих цілей застосовуються такі положення:
        </p>
        <p>
            Персональні дані: дані ідентифікації, контактні дані, дані про ваш пристрій, дані про використання
            веб-сайту, дані про використання послуг та дані про симульовані угоди.
        </p>
        <p>
            Правова основа обробки: Обробка здійснюється на підставі наших законних інтересів, зокрема, встановлення
            поведінки клієнтів та оцінки їхніх вподобань.
        </p>
    </div>
    <div class="subheader">4. ЯК МИ ЗБЕРІГАЄМО ПЕРСОНАЛЬНІ ДАНІ І ЯК ЇХ ЗАХИЩАЄМО?</div>
    <div class="text">
        <p>
            Ми обробляємо ваші персональні дані в зашифрованій базі даних на комп'ютерах та інших пристроях. Для
            забезпечення захисту від несанкціонованого доступу, зміни, розкриття або знищення даних, які ми збираємо та
            обробляємо, ми вживаємо організаційних та технічних заходів для їх захисту, які ми суворо дотримуємо.
        </p>
    </div>
    <div class="subheader">5. ЯК ДОВГО МИ ОБРОБЛЯЄМО ВАШІ ДАНІ?</div>
    <div class="text">
        <p>
            Персональні дані обробляються в міру необхідності для досягнення вищезазначених цілей або на протязі
            періоду, безпосередньо встановленого законом. Після цього персональні дані видаляються або анонімізуються.
        </p>
    </div>
    <div class="subheader">6. ЧИ ПЕРЕДАЄМО МИ ВАШІ ДАНІ ТРЕТІМ ОСОБАМ (СТОРОНАМ)?</div>
    <div class="text">
        <p>
            Ми надаємо ваші дані або передаємо їх особам, які допомагають нам надавати наші послуги, зокрема таким
            особам:
        </p>
        <p>
            Постачальник торговельної платформи, щоб дозволити вам здійснювати симульовані угоди.
        </p>
        <p>
            Інші треті сторони, що допомагають нам управляти нашим веб-сайтом та надавати наші послуги (такі як інші
            компанії нашої групи, постачальники хостингу та хмарних сервісів, оператори платіжних систем та фінансових
            установ, постачальники інструментів та послуг підтримки клієнтів, ІТ-компанії та системні адміністратори,
            маркетингові та комунікаційні агентства, консультанти та поштові служби);
        </p>
        <p>
            Крім того, ми можемо передавати ваші дані особам, щодо яких ви надаєте згоду на це, а також у випадках, коли
            це вимагається законом або судовим рішенням або іншим юридично зобов'язуючим рішенням. Кожна така особа,
            якій передаються персональні дані, зобов'язана укласти контрактну угоду про захист ваших персональних даних
            відповідно до законодавчих норм і обробляти дані виключно згідно наших інструкцій.
        </p>
        <p>
            Деякі треті сторони збирають персональні дані для власних цілей як власники даних. У такому випадку
            застосовуються їх власні правила щодо обробки персональних даних.
        </p>
    </div>
    <div class="subheader">7. ВАШІ ПРАВА</div>
    <div class="text">
        <p>
            Ви можете використовувати всі нижчезазначені права, включаючи ваше право на видалення персональних даних та
            відкликання згоди, надіславши запит до нас за адресою info@flowspot.trade, або використовуючи наші контактні
            дані, вказані в Розділі 1 цієї Політики конфіденційності. Вам не потрібно використовувати певні слова у
            вашому запиті, але будьте достатньо конкретними, щоб ми могли допомогти вам з вашим запитом (наприклад,
            ідентифікуйте себе, вкажіть право, яке ви бажаєте здійснити, яку дію ви хочете, щоб ми виконали, вказуйте
            деталі щодо персональних даних, які ви хочете видалити, тощо).
        </p>
        <p>
            У зв'язку з обробкою персональних даних у вас є такі права:
        </p>
        <p>
            <b>Право на доступ до персональних даних</b>
        </p>
        <p>
            Ви можете звернутися до нас у будь-який час з проханням надіслати вам підтвердження про те, чи обробляються
            ваші персональні дані. Якщо ми обробляємо ваші дані, ми надамо вам додаткові деталі щодо обробки. Якщо ви
            запросите це, ми також надамо вам копію оброблених персональних даних. Зверніть увагу, що перша копія
            надається безкоштовно, але за наступні копії може стягуватися невелика плата.
        </p>
        <p>
            <b>Право на виправлення персональних даних</b>
        </p>
        <p>
            Якщо ми неправильно обробляємо ваші персональні дані, ви можете повідомити нас про це, і ми виправимо
            неправильні персональні дані без неприпустимої затримки. Якщо ви зареєструвалися на веб-сайті, ви зможете
            самостійно виправити та змінити свої персональні дані, редагуючи свій користувацький обліковий запис.
        </p>
        <p>
            <b>
                Право на видалення персональних даних ("право на забуття")
            </b>
        </p>
        <p>
            У вас є право вимагати від нас видалення персональних даних, що стосуються вас, без неприпустимої затримки у
            наступних випадках:
        </p>
        <p class="sub">• Якщо дані більше не потрібні для цілей, для яких ми зібрали або іншим чином обробляли їх;</p>
        <p class="sub">• Якщо ви відкликаєте свою згоду на їх обробку і в той же час не існує іншої законної підстави
            для їх обробки (це стосується лише випадків, коли ми обробляємо персональні дані на основі вашої згоди);</p>
        <p class="sub">• Якщо ви заперечуєте, і не існують превалюючі законні підстави для обробки, або якщо ви
            заперечуєте обробку з метою прямого маркетингу; або</p>
        <p class="sub">• Якщо ваші персональні дані обробляються незаконно.</p>
        <p>
            Ми не зможемо виконати запит на видалення персональних даних, якщо їх обробка необхідна для здійснення права
            на свободу вираження та інформацію, для виконання будь-яких наших юридичних обов'язків, для виконання
            завдання, що здійснюється в інтересах громадськості, для встановлення, здійснення або захисту наших
            юридичних вимог або за інших підстав, передбачених законом.
        </p>
        <p>
            <b>
                Право на обмеження обробки
            </b>
        </p>
        <p>
            У випадках, зазначених у статті 18 Загального регламенту про захист даних (GDPR), у вас є право вимагати
            обмеження обробки ваших персональних даних на певний період.
        </p>
        <p>
            <b>
                Право на перенесення даних та отримання даних у машинночитному форматі
            </b>
        </p>
        <p>
            У разі автоматизованої обробки на підставі вашої згоди або виконання договору у вас є право отримати дані в
            структурованому, загальноприйнятому форматі, придатному для машинного читання, і мати їх передані нами
            іншому контролеру персональних даних.
        </p>
        <p>
            <b>
                Право на заперечення
            </b>
        </p>
        <p>
            Якщо ми обробляємо персональні дані на підставі наших законних інтересів, у вас є право заперечити таку
            обробку. Якщо ви подаєте таке заперечення, ми не зможемо обробляти ваші персональні дані, якщо ми не
            продемонструємо переважаючих законних підстав для обробки, які перевищують ваші інтереси або права і
            свободи, або для встановлення, здійснення або захисту наших юридичних вимог.
        </p>
        <p>
            Якщо ми обробляємо ваші персональні дані для маркетингових цілей стосовно наших продуктів і послуг, ми
            припинимо обробку без неприпустимої затримки після отримання заперечення. В такому випадку ми більше не
            зможемо надсилати вам пропозиції нашої продукції та послуг.
        </p>
        <p>
            <b>
                Право відкликати згоду
            </b>
        </p>
        <p>
            Якщо обробка ґрунтується на вашій згоді, ви маєте право відкликати цю згоду в будь-який час. Відкликання
            згоди не впливає на законність обробки, здійсненої на підставі згоди, даної до її відкликання.
        </p>
        <p>
            <b>Право не бути предметом автоматизованого прийняття рішень</b>
        </p>
        <p>
            Крім випадків, коли обробка необхідна для укладання або виконання договору, якщо це допускається застосовним
            законодавством або на підставі вашої явної згоди, у вас є право не бути предметом рішення, яке базується
            виключно на автоматизованій обробці, включаючи профілювання, яке призводить до правових наслідків для вас
            або має значний вплив на вас.
        </p>
        <p>
            <b>
                Право подати скаргу до наглядового органу
            </b>
        </p>
        <p>
            Якщо ви вважаєте, що ми обробляємо ваші дані з порушенням відповідних правових норм, ви можете подати скаргу
            до відповідного наглядового органу.
        </p>
    </div>
    <div class="subheader">ЗАКЛЮЧНІ ПОЛОЖЕННЯ</div>
    <div class="text">
        <p>
            Ми можемо односторонньо змінювати цю політику, але ми зробимо це тільки у необхідних випадках і повідомимо
            вас про такі зміни. Ви можете знайти останню версію цієї політики у вашому Клієнтському розділі.
        </p>
    </div>
    <p></p>
    <p></p>
    <p></p>
</div>