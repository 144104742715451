import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelpersService {
  constructor() { }
  // Remember last visited page
  setLastVisitedPage(url:string){
    localStorage.setItem('url', url);
  }
}
