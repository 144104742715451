import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../shared/services/auth.service';
import { GetInfoService } from '../../shared/services/get-info.service';
// import { ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatDrawerMode } from '@angular/material/sidenav';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit {
  user: any;
  userPhotoUrl:string= "../../../assets/images/photo-default.png"; 
  panelOpenState = false;
  opened!: boolean;
  isRender: boolean = false;
  studentDataLoaded: boolean = false;
  id: any = "";
  student: any={
    first_test:false
  };
  navState:any;
  
  isPhotoButton:boolean = false;
  isHideNavPanel:boolean = false;
  contentMargin=328;
  isMobile=false;
  isMedium=false;
  constructor(
    public authService: AuthService,
    public getInfoService: GetInfoService,
    public router: Router,
    private responsive: BreakpointObserver,
  ) { }


  async ngOnInit(): Promise<void> {

    this.authService.uidAdded();
    this.authService.storageChange.subscribe(async (isAdd) => {
      if (isAdd) {
        this.id = localStorage.getItem('uid');
        (await this.getInfoService.getStudentInfo(this.id)).subscribe((result: any) => {
          this.student = result
          if (result) { this.studentDataLoaded = true }
        })
        this.getUser();

      }
    })
    this.responsive.observe(['(max-width: 767.98px)', '(min-width: 768px and max-width: 1022.98px)', '(min-width: 1023px)'])
    .subscribe(() => this.breakpointChanged()
    );

  }

  private breakpointChanged() {
    if (this.responsive.isMatched('(max-width: 767.98px)')) {
      this.isPhotoButton=false;
      this.isMobile=true;
      this.isMedium=false;
      this.isHideNavPanel=false;
      this.navState=false;
    }
    if (this.responsive.isMatched('(max-width: 1022.98px)') && this.responsive.isMatched('(min-width:768px)')) {
      this.isPhotoButton=true;
      this.isHideNavPanel=true;
      this.contentMargin=76;
      this.navState=false;
      this.isMobile=false;
      this.isMedium=true;
    }
    if (this.responsive.isMatched('(min-width: 1023px)')) { 
      this.isPhotoButton=false;
      this.isMobile=false;
      this.isMedium=false;
      this.isHideNavPanel=false;
      this.navState=true;
    }
  }

  photoButtonClick() {
    this.isHideNavPanel= !this.isHideNavPanel;
  }
  test(){
    console.log("hi")
  }

  logOut = async () => {
    await this.authService.logOut();

    const navigationDetails: string[] = [''];
    
    await this.router.navigate(navigationDetails);
    // window.location.reload();
  }
  getUser = async () => {
    (await this.getInfoService.getUserInfo(this.id!)).subscribe((res)=>{
      if (res){
        this.user = res;
        this.isRender = true;
        if (res.photo){
          this.userPhotoUrl=res.photo;
        }
      }
    })
  }
}