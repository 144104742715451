import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../shared/services/auth.service';
import { GetInfoService } from '../../shared/services/get-info.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { HttpClient } from '@angular/common/http';
import { AngularFireAnalytics } from '@angular/fire//compat/analytics';
import { AngularFireRemoteConfig } from '@angular/fire/compat/remote-config';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
    public authService: AuthService,
    private getInfo: GetInfoService,
    private responsive: BreakpointObserver,
    public http: HttpClient,
    private remoteConfig: AngularFireRemoteConfig,
    private analytics: AngularFireAnalytics
  ) { }
  isAuthenticated: any;
  feedbacks!: any;
  firstPanelState: boolean = true;
  secondPanelState: boolean = false;
  thirdPanelState: boolean = false;

  isMobile = false;
  isLarge = true;
  isShowCarouselControls: boolean = true;
  sliderItemWidth: string = "352";
  thirdBlockShowButton: boolean = true;
  showNew: boolean = false;
  showText: boolean = false;
  abTestVariant:any;
  ngOnInit(): void {
    this.remoteConfig.fetchAndActivate().then(async () => {
      const abTestVariant = await this.remoteConfig.getString('landing_ab_testing');
      localStorage.setItem('test', abTestVariant);
      this.abTestVariant = abTestVariant;
      this.applyConfigChanges();
      this.logAppInitializedEvent()
    });
    this.authService.authStatusListener();
    this.authService.currentAuthStatus.subscribe(authStatus => this.isAuthenticated = authStatus)
    this.getInfo.getFeedbacks().subscribe((res) => {
      this.feedbacks = res
    })
    this.responsive.observe(['(max-width: 767.98px)', '(min-width: 768px and max-width: 1022.98px)', '(min-width: 1023px)'])
      .subscribe(() => this.breakpointChanged()
      );
  }

  private async applyConfigChanges() {
    // Пример использования Remote Config
   
    // Логика для A/B-тестирования
    if (this.abTestVariant) {
      this.showText = true;
    }
    if (this.abTestVariant === 'variantA') {
      this.showNew = false;
    } else if (this.abTestVariant === 'variantB') {
      this.showNew = true;
    }
  }
  async vis2reg(){
    this.analytics.logEvent('vis2reg', {
      variant: this.abTestVariant,
    });
  }

  private async logAppInitializedEvent() {
    // Отправка события аналитики при инициализации приложения
    this.analytics.logEvent('landing_initialized',{
      variant: this.abTestVariant,
    });
  }


  private breakpointChanged() {
    if (this.responsive.isMatched('(max-width: 767.98px)')) {
      this.isLarge = false;
      this.isMobile = true;
      this.isShowCarouselControls = false;
      this.sliderItemWidth = "212"
      this.thirdBlockShowButton = true;
    }
    if (this.responsive.isMatched('(max-width: 1022.98px)') && this.responsive.isMatched('(min-width:768px)')) {
      this.isLarge = false;
      this.isMobile = false;
      this.isShowCarouselControls = true;
      this.sliderItemWidth = "352"
      this.thirdBlockShowButton = false;
    }
    if (this.responsive.isMatched('(min-width: 1023px)')) {
      this.isMobile = false;
      this.isLarge = true;
      this.thirdBlockShowButton = true;
    }
  }
  accordeonChange() {
    if (this.firstPanelState == false && this.secondPanelState == false && this.thirdPanelState == false) {
      this.firstPanelState = true;
    }
  }
}
