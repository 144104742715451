<mat-sidenav-container *ngIf="isRender" class="example-container" hasBackdrop=false>

    <mat-sidenav #sidenav [mode]="isMobile||isMedium?'over':'side'" [class.hide]="isHideNavPanel" [opened]="navState"
        (opened)="navState=true" (closed)="navState=false">
        <div class="nav-user">
            <button class="photo-button" mat-button (click)="sidenav.toggle();isHideNavPanel=navState"
                [disabled]="!isPhotoButton">
                <img class="user-photo" src="{{userPhotoUrl}}" alt="">
            </button>
            <div class="user-name">Вітаємо, {{user.firstName}}!</div>
            <a routerLink="profile">
                <div class="nav-close" *ngIf="isMobile" (click)="sidenav.toggle()"></div>
                <div class="nav-close" *ngIf="isMedium" (click)="sidenav.toggle();isHideNavPanel=navState"></div>
                <img class="red-icon" src="./../../../assets/images/red-icon.png" alt="">
            </a>
        </div>
        <mat-accordion *ngIf="this.studentDataLoaded" multi="true">
            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Етап 1.
                        <div class="undone" [class.done]="true"></div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-nav-list class="nav-list">
                    <a mat-list-item routerLinkActive="active-list-item" routerLink="lesson/company" class="nav-button">
                        <div class="nav-close" *ngIf="isMobile" (click)="sidenav.toggle()"></div>
                        <div class="nav-close" *ngIf="isMedium" (click)="sidenav.toggle();isHideNavPanel=navState">
                        </div><span class="nav-text">Компанія</span>
                    </a>
                    <a mat-list-item routerLinkActive="active-list-item" routerLink="lesson/market" class="nav-button">
                        <div class="nav-close" *ngIf="isMobile" (click)="sidenav.toggle()"></div>
                        <div class="nav-close" *ngIf="isMedium" (click)="sidenav.toggle();isHideNavPanel=navState">
                        </div>
                        <span class="nav-text">Механіка ринку</span>
                    </a>
                    <!-- <a mat-list-item routerLinkActive="active-list-item" routerLink="test/first_test"
                        class="nav-button">
                        <div class="nav-close" *ngIf="isMobile" (click)="sidenav.toggle()"></div>
                        <div class="nav-close" *ngIf="isMedium" (click)="sidenav.toggle();isHideNavPanel=navState">
                        </div>
                        <span class="nav-text">Тест 1</span>
                    </a> -->
                </mat-nav-list>
            </mat-expansion-panel>
            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Етап 2.
                        <div class="undone" [class.done]="this.student.second_test"></div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-nav-list class="nav-list">
                    <a mat-list-item routerLinkActive="active-list-item" routerLink="profile"
                        routerLink="lesson/moonbot" [disabled]="false" class="nav-button">
                        <div class="nav-close" *ngIf="isMobile" (click)="sidenav.toggle()"></div>
                        <div class="nav-close" *ngIf="isMedium" (click)="sidenav.toggle();isHideNavPanel=navState">
                        </div>
                        <span class="nav-text">Moonbot</span>
                    </a>
                    <a mat-list-item routerLinkActive="active-list-item" routerLink="profile"
                        routerLink="lesson/strategies" [disabled]="false" class="nav-button">
                        <div class="nav-close" *ngIf="isMobile" (click)="sidenav.toggle()"></div>
                        <div class="nav-close" *ngIf="isMedium" (click)="sidenav.toggle();isHideNavPanel=navState">
                        </div><span class="nav-text">Strategies</span>
                    </a>
                    <a mat-list-item routerLinkActive="active-list-item" routerLink="test/second_test"
                        [disabled]="false" class="nav-button">
                        <div class="nav-close" *ngIf="isMobile" (click)="sidenav.toggle()"></div>
                        <div class="nav-close" *ngIf="isMedium" (click)="sidenav.toggle();isHideNavPanel=navState">
                        </div><span class="nav-text">Тест 2</span>
                    </a>
                </mat-nav-list>
            </mat-expansion-panel>
            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Етап 3.
                        <div class="undone" [class.done]="this.student.third_test"></div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-nav-list class="nav-list">
                    <a mat-list-item routerLinkActive="active-list-item" routerLink="profile" routerLink="lesson/dump"
                        [disabled]="!this.student.second_test" class="nav-button">
                        <div class="nav-close" *ngIf="isMobile" (click)="sidenav.toggle()"></div>
                        <div class="nav-close" *ngIf="isMedium" (click)="sidenav.toggle();isHideNavPanel=navState">
                        </div>
                        <span class="nav-text">Dump</span>
                    </a>
                    <a mat-list-item routerLinkActive="active-list-item" routerLink="test/third_test"
                        [disabled]="!this.student.second_test" class="nav-button">
                        <div class="nav-close" *ngIf="isMobile" (click)="sidenav.toggle()"></div>
                        <div class="nav-close" *ngIf="isMedium" (click)="sidenav.toggle();isHideNavPanel=navState">
                        </div>
                        <span class="nav-text">Тест 3</span>
                    </a>
                </mat-nav-list>
            </mat-expansion-panel>
            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Етап 4.
                        <div class="undone" [class.done]="this.student.fourth_test"></div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-nav-list class="nav-list">
                    <a mat-list-item routerLinkActive="active-list-item" routerLink="profile" routerLink="lesson/pump"
                        [disabled]="!this.student.third_test" class="nav-button">
                        <div class="nav-close" *ngIf="isMobile" (click)="sidenav.toggle()"></div>
                        <div class="nav-close" *ngIf="isMedium" (click)="sidenav.toggle();isHideNavPanel=navState">
                        </div><span class="nav-text">Pump</span>
                    </a>
                    <a mat-list-item routerLinkActive="active-list-item" routerLink="test/fourth_test"
                        [disabled]="!this.student.third_test" class="nav-button">
                        <div class="nav-close" *ngIf="isMobile" (click)="sidenav.toggle()"></div>
                        <div class="nav-close" *ngIf="isMedium" (click)="sidenav.toggle();isHideNavPanel=navState">
                        </div><span class="nav-text">Тест 4</span>
                    </a>
                </mat-nav-list>
            </mat-expansion-panel>
            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Етап 5.
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-nav-list class="nav-list">
                    <a mat-list-item routerLinkActive="active-list-item" routerLink="profile"
                        routerLink="lesson/discord" [disabled]="!this.student.fourth_test" class="nav-button">
                        <div class="nav-close" *ngIf="isMobile" (click)="sidenav.toggle()"></div>
                        <div class="nav-close" *ngIf="isMedium" (click)="sidenav.toggle();isHideNavPanel=navState">
                        </div><span class="nav-text">Discord</span>
                    </a>
                    <a mat-list-item routerLinkActive="active-list-item" routerLink="profile"
                        routerLink="lesson/active_market" [disabled]="!this.student.fourth_test" class="nav-button">
                        <div class="nav-close" *ngIf="isMobile" (click)="sidenav.toggle()"></div>
                        <div class="nav-close" *ngIf="isMedium" (click)="sidenav.toggle();isHideNavPanel=navState">
                        </div><span class="nav-text">Бурхливий ринок</span>
                    </a>
                </mat-nav-list>
            </mat-expansion-panel>
        </mat-accordion>
    </mat-sidenav>
    <mat-sidenav-content [ngStyle]="{'margin-left.px':contentMargin}">
        <div *ngIf="isMobile&&navState" (click)="sidenav.toggle()" class="nav-close"></div>
        <div class="nav-close" *ngIf="isMedium&&navState" (click)="sidenav.toggle();isHideNavPanel=navState"></div>
        <img *ngIf="isMobile" src="../../../assets/images/burger-icon.png" class="burger-icon"
            (click)="sidenav.toggle()">
        <div class="grow-bar">
            <div class="stage-container">
                <div class="stage" [class.done]="true">
                    <div *ngIf="!isMobile" class="stage-text">Етап 1</div>
                </div>
            </div>
            <div class="stage-container">
                <div class="stage" [class.done]="this.student.first_test">
                    <div *ngIf="!isMobile" class="stage-text">Етап 2</div>
                </div>
            </div>
            <div class="stage-container">
                <div class="stage" [class.done]="this.student.second_test">
                    <div *ngIf="!isMobile" class="stage-text">Етап 3</div>
                </div>
            </div>
            <div class="stage-container">
                <div class="stage" [class.done]="this.student.third_test">
                    <div *ngIf="!isMobile" class="stage-text">Етап 4</div>
                </div>
            </div>
            <div class="stage-container last-stage">
                <div class="stage" [class.done]="this.student.fourth_test">
                    <div *ngIf="!isMobile" class="stage-text"> Етап 5</div>
                </div>
            </div>
            <div [class.mobile-log-out]="isMobile" class="log-out-container">
                <div class="log-out-btn" (click)="this.logOut()">
                    <img src="../../../assets/images/log-out.png" alt="">
                    Вихід
                </div>
            </div>
        </div>
        <router-outlet></router-outlet>
        <a href="https://t.me/flowspot_chat" class="tg-chat" target="_blank">
            <span>Маєш питання?<br>З радістю відповімо!</span>
            <img src="../../../assets/images/tg-chat.png" alt="">
        </a>
    </mat-sidenav-content>
</mat-sidenav-container>