import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { getFirestore, doc, setDoc, addDoc, getDoc } from '@angular/fire/firestore';
import { Firestore, collectionData, collection } from '@angular/fire/firestore';
import { inject } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class WriteDataService {
  firestore: Firestore = inject(Firestore);

  constructor(
    private fns: AngularFireFunctions
  ) { }

  async addUserData(firstName: any, lastName: any, phone: string, birthDate: string, city: string, discord: string, interrogation: string) {
    const addData = this.fns.httpsCallable('fullUserProfile');
    const id = localStorage.getItem('uid');
    return addData({ firstName, lastName, phone, birthDate, city, discord, id, interrogation})
  }
  updateUserPhoto = async (photoUrl: string, id: string) => {
    const db = getFirestore();
    const docRef = doc(db, "users", id);
    return await setDoc(docRef, { photo: photoUrl }, { merge: true })
  }
  changeActivityMarker = async (id: string) => {
    const db = getFirestore();
    const docRef = doc(db, "users", id);
    const docSnap = await getDoc(docRef);
    let email = "";
    let activityMarker = false;
    if (docSnap.exists()) {
      email = docSnap.data()["email"];
      activityMarker = docSnap.data()["avtivityMarker"];
    }
    if (!activityMarker) {
      await setDoc(docRef, { activityMarker: true }, { merge: true });
      if (email) {
        await addDoc(collection(db, "mail"), {
          to: [`${email}`],
          template: {
            name: "Module1",
            data: {
              unsubscribe_url: "{{unsubscribe_url}}"
            }
          }
        });
      }
    }
  }
}
