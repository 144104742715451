import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tg-integration',
  templateUrl: './tg-integration.component.html',
  styleUrls: ['./tg-integration.component.scss']
})
export class TgIntegrationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
