<div class="container">
    <div class="go-back-btn" (click)="this.backClicked()">
        <img src="../../../assets/images/go-back.png" alt="">
        Назад
    </div>
    <p></p>
    <p></p>
    <p></p>
    <div class="header">
        <b>Угода користувача <br>
            FLOWSPOT</b>
    </div>
    <p></p>
    <div class="term-date">
        Дата набуття чинності: 10 листопада 2023 року <br>
        Попередня версія: 01 травня 2023 року
    </div>
    <p></p>
    <div class="text">
        <p>
            Ця Угода користувача (далі – «Угода», «Умови користувача», «Умови») становлять юридичний договір між вами як
            користувачем («користувач», «ви», «ваш») веб-сайту https://flowspot.trade («Веб-сайт») і нами, ТОВ
            «ФЛОВСПОТ» (код ЄДРПОУ - 45175889) («ми», «FLOWSPOT», «Холдинг»).
        </p>
        <p>
            Угода регулює ваші права та обов'язки щодо використання Веб-сайту та отримання послуг. Користувачі та
            FLOWSPOT разом зазначаються як Сторони, окремо - Сторона. Простим доступом до нашого Веб-сайту ви
            погоджуєтесь повністю підпорядковуватися Умовам без будь-яких застережень. Якщо ви не погоджуєтесь бути
            зв'язаними цими Умовами, будь ласка, не використовуйте Веб-сайт. Якщо ви хочете дізнатися, як ми обробляємо
            ваші персональні дані, ознайомтеся з нашою Політикою конфіденційності FLOWSPOT.
        </p>
    </div>
    <div class="subheader">1. Загальні положення</div>
    <div class="text">
        <p>
            <b>1.1.</b> Веб-сайт призначений для надання безкоштовного онлайн навчання з трейдингу на криптовалютному
            ринку через Веб-сайт, а також необхідних суміжних інформаційних послуг ("Послуги").
        </p>
        <p>
            <b>1.2.</b> Оскільки ми використовуємо Веб-сайт як основний інструмент надання Послуг, Веб-сайт має
            функціональні
            можливості, які полегшують надання Послуг. Оскільки ми дозволяємо користувачам використовувати ці можливості
            для поліпшення досвіду використання Послуг, будь ласка, розглядайте Веб-сайт як частину наших Послуг.
        </p>
        <p>
            <b>1.3.</b> Ми залишаємо за собою право додавати будь-які нові функціональні можливості на Веб-сайт у
            будь-який час. Якщо новостворені функціональні можливості Веб-сайту не регулюються окремо Умовами, вони
            надаються "як є" і підпорядковуються Умовам в мірі, що є розумно можливим.
        </p>
        <p>
            <b>1.4.</b> Ми можемо змінювати, призупиняти або припиняти роботу цього Веб-сайту або Послуг повністю або
            частково
            в будь-який час і з будь-якої причини без попереднього повідомлення вам.
        </p>
    </div>
    <div class="subheader">
        2. Право на отримання Послуг
    </div>
    <div class="text">
        <p>
            <b>2.1.</b> Ви можете використовувати Веб-сайт та отримувати Послуги лише як фізична особа з повною
            юридичною
            дієздатністю згідно з законами вашого місцезнаходження. Якщо ви є неповнолітньою особою згідно з законами
            вашого місцезнаходження, ви не можете використовувати Веб-сайт та отримувати Послуги. При створенні
            облікового запису користувача вам може бути запропоновано підтвердити свій вік та особу.
        </p>
        <p>
            <b>2.2.</b> Незареєстровані користувачі можуть мати доступ лише до певних сторінок та функціоналу Веб-сайту,
            переважно для інформаційних цілей.
        </p>
    </div>
    <div class="subheader">
        3. Реєстрація облікового запису користувача. Оцінка
    </div>
    <div class="text">
        <p>
            <b>3.1.</b> Ви можете створити обліковий запис користувача на нашому Веб-сайті.
        </p>
        <p>
            <b>3.2.</b> Якщо ви подаєте заявку на повноцінну програму навчання, вам потрібно пройти процес оцінки.
            Оцінка
            складається з попереднього тесту. Після подання заявки ми надамо вам матеріали, які допоможуть вам
            підготуватися до тесту.
        </p>
        <p>
            <b>3.3.</b> Ми за своїм власним розсудом залишаємо за собою право відмовити у запуску вашої оцінки або
            наданні Послуг у будь-який час після успішної оцінки. Ви згодні не оскаржувати наше рішення щодо такої
            відмови.
        </p>
        <p>
            <b>3.4.</b> Для подання заявки вам може знадобитися заповнити нашу форму з вашою інформацією.
        </p>
        <p>
            <b>3.5.</b> Цей обліковий запис дозволить вам брати участь у подіях, отримувати корисну інформацію,
            повідомлення,
            записи подій, запрошення на подальші події та виконувати завдання.
        </p>
        <p>
            <b>3.6.</b> Створений обліковий запис користувача може використовуватися тільки для вищезазначених цілей.
        </p>
        <p>
            <b>3.7.</b> Ви вільні вирішувати, чи надавати додаткову особисту інформацію для свого облікового запису.
        </p>
        <p>
            <b>3.8.</b> Реєструючись на Веб-сайті, ви заявляєте і гарантуєте, що:
        </p>
        <p class="sub">
            <b>3.8.1.</b> Будете отримувати Послуги відповідно до Умов та не будете використовувати Послуги незаконним
            способом;
        </p>
        <p class="sub">
            <b>3.8.2.</b> Надали справжню, валідну та точну особисту інформацію і підтримуватимете її актуальність увесь
            час;
        </p>
        <p class="sub">
            <b>3.8.3.</b> Не будете здійснювати дії, спрямовані на порушення нормального функціонування Веб-сайту, як
            через програмне забезпечення, так і шляхом прямих дій на Веб-сайті, або вчинення будь-яких інших незаконних
            дій;
        </p>
        <p class="sub">
            <b>3.8.4.</b> Несете відповідальність за захист вашого облікового запису від несанкціонованого використання;
        </p>
        <p class="sub">
            <b>3.8.5.</b> Особисто відповідальні за надання доступу до вашого облікового запису будь-яким третім особам
            і наслідки цього;
        </p>
        <p class="sub">
            <b>3.8.6.</b> Не будете створювати більше одного облікового запису на одну особу;
        </p>
        <p class="sub">
            <b>3.8.7.</b> Не маєте прав чи пільг щодо будь-якого контенту, матеріалів, продуктів, розроблених FLOWSPOT
            на Веб-сайті;
        </p>
        <p class="sub">
            <b>3.8.8.</b> Не будете скрапити, створювати бази даних або іншим чином створювати постійні копії наданого
            контенту на Веб-сайті і поза ним, за винятком випадків, пов'язаних з наданням Послуг, або зберігати кешовані
            копії довше, ніж дозволяє кеш-заголовок;
        </p>
        <p class="sub">
            <b>3.8.9.</b> Не порушуватимете наші права інтелектуальної власності;
        </p>
        <p class="sub">
            <b>3.8.10.</b> Не порушуватимете права інших користувачів.
        </p>
        <p>
            <b>3.9.</b> Ми не дозволяємо зареєстрованим користувачам надавати доступ до свого облікового запису іншим
            особам. У
            разі надання такого доступу ми можемо припинити роботу вашого облікового запису та припинити надання Послуг.
        </p>
        <p>
            <b>3.10.</b> Ви погоджуєтеся негайно повідомити нас, якщо ви вважаєте або маєте обґрунтовані підстави
            вважати, що вашим обліковим записом користувалися без вашого дозволу. У разі неповідомлення ви, як законний
            власник облікового запису, несете відповідальність за всі дії, здійснені від вашого імені, за винятком
            випадків, коли таке неповідомлення було спричинене вашою необізнаністю або нездатністю.
        </p>
        <p>
            <b>3.11.</b> Ви вільні в будь-який час видалити свій обліковий запис, якщо немає невиконаних зобов'язань
            перед FLOWSPOT. Коли ви маєте такі зобов'язання, ви спочатку повинні виконати всі зобов'язання. Після цього
            ви зможете видалити обліковий запис.
        </p>
    </div>
    <div class="subheader">
        4. Становлення студентом
    </div>
    <div class="text">
        <p>
            <b>4.1.</b> Студентом є зареєстрований користувач Веб-сайту, який успішно пройшов оцінку для повноцінної
            програми
            навчання ("Студент", “Стажер”). Після отримання статусу Студента, ми переключимо ваш обліковий запис на
            обліковий запис Студента. Студенти отримують доступ до повного спектру функцій і можливостей Веб-сайту.
        </p>
        <p>
            <b>4.2.</b> Перебуваючи у статусі Студента та отримуючи Послуги, ви зобов'язані відноситися з загальним
            повагою до
            персоналу FLOWSPOT, включаючи наставників, тренерів, їх помічників та нашу команду підтримки користувачів.
            Ми можемо припинити дію Умов з вами, якщо буде зафіксовано таку неуважну поведінку.
        </p>
        <p>
            <b>4.3.</b> Студент також підписує Регламент ( з FLOWSPOT або її агентами ("Угода про надання Послуг")).
        </p>
    </div>
    <div class="subheader">
        5. Навчальні послуги
    </div>
    <div class="text">
        <p>
            <b>5.1.</b> Ми надаємо Послуги за допомогою онлайн-курсів ("курс"). Курс - це програма, яка складається з
            декількох
            модулів на різні теми, розташованих у певній послідовності. Модулі, в свою чергу, складаються з низки
            щоденних занять.
        </p>
        <p>
            <b>5.2.</b> Ми повідомимо Студентів про дату початку курсу, який вони обрали при поданні заявки на оцінку та
            планування курсу.
        </p>
        <p>
            <b>5.3.</b> Студентам потрібно завершити кожний модуль перед переходом до наступного модуля.
        </p>
        <p>
            <b>5.4.</b> Студентам можуть бути надані тренери, наставники, координатори на протязі всього курсу в
            залежності від
            обраної програми навчання. Тренери, наставники та координатори допоможуть вам освоїти курс, надаючи та
            пояснюючи вам навчальні матеріали, знання, досвід, проводячи лекції та сесії питань і відповідей,
            переглядаючи вашу роботу, надаючи фідбек та керуючи вами в процесі навчання.
        </p>
        <p>
            <b>5.5.</b> Протягом курсу ми поступово надаватимемо вам доступ до навчальних матеріалів, необхідних для
            завершення
            курсу.
        </p>
        <p>
            <b>5.6.</b> Повноцінна програма навчання передбачає виконання завдань та індивідуальну роботу. Ви згодні
            вчасно
            подавати свою роботу і робити найкращі зусилля. У разі пропуску термінів або постійного подання роботи
            низької якості, ви стикаєтесь з наслідками, передбаченими відповідною Регламенту.
        </p>
        <p>
            <b>5.7.</b> Наші тренери та наставники зобов'язані надавати вам відгук з поясненням сильних сторінок і
            недоліків вашої роботи.
        </p>
        <p>
            <b>5.8.</b> Якщо ви вирішите пройти ще один курс, вам потрібно буде пройти оцінку, специфічну для цього
            курсу.
        </p>
        <p>
            <b>5.9.</b> Протягом курсу ви згодні бути доступним для курсу і заявляєте та гарантуєте, що у вас не буде
            інших
            зобов'язань, які можуть перешкодити отриманню Послуг.
        </p>
        <p>
            <b>5.10.</b> Під час отримання Послуг ми можемо рекомендувати вам використовувати сторонні веб-сайти,
            онлайн-сервіси, програмне забезпечення або інші товари чи послуги. Ці рекомендації надаються вам виключно з
            метою успішного завершення навчальних матеріалів курсу.
        </p>
        <p>
            <b>5.11.</b> FLOWSPOT не несе відповідальності за зміст будь-яких сторонніх продуктів або послуг, на які є
            посилання. Будь-які сторонні продукти або послуги є незалежними від FLOWSPOT. FLOWSPOT не має контролю над
            ними. Використання будь-якого стороннього продукту або сервісу підлягає його умовам надання послуг та
            політиці конфіденційності або іншій правовій документації.
        </p>
        <p>
            <b>5.12.</b> В жодному випадку будь-яке посилання на будь-який сторонній продукт або сервіс не може
            розглядатися як схвалення або підтримка FLOWSPOT цієї сторони або будь-якого продукту чи послуги, що
            надається третьою стороною. Рекомендація використовувати будь-який сторонній продукт або сервіс не означає,
            що ми підтримуємо або несемо відповідальність за зміст або використання такого продукту або сервісу, а також
            не означає, що ми є стороною або будь-яким чином контролюємо будь-яку угоду з третіми сторонами, щодо
            продуктів або послуг.
        </p>
    </div>
    <div class="subheader">
        6. Комунікація
    </div>
    <div class="text">
        <p>
            <b>6.1.</b> Крім випадків, коли ви інакше вказали письмово, ми будемо зв'язуватися з вами за допомогою
            електронної
            пошти, звичайної пошти, публічно доступного вмісту або через чат, включаючи чати в месенджерах та програмах,
            які доступні вам. Ви погоджуєтесь отримувати комунікації від нас електронним шляхом, і ви погоджуєтесь, що
            ці електронні комунікації задовольняють будь-які правові вимоги, що такі комунікації повинні бути
            письмовими. Вважається, що ви отримали комунікацію, коли ми надіслали її у чат вашого облікового запису або
            на електронну адресу, яку ви надали нам, або коли ми розміщуємо вміст на Веб-сайті. Ви повинні підтримувати
            актуальну електронну адресу на Веб-сайті та регулярно перевіряти свій обліковий запис на Веб-сайті для
            отримання повідомлень.
        </p>
        <p>
            <b>6.2.</b> Щоб перестати отримувати певні комунікації від FLOWSPOT, будь ласка, повідомте нам або внесіть
            відповідні зміни в налаштування облікового запису, щоб змінити типи і частоту таких комунікацій.
        </p>
        <p>
            <b>6.3.</b> Використовуючи наш Веб-сайт та отримуючи наші Послуги, ви вільно і виразно надаєте свою згоду на
            доступ до ваших даних, які ви надаєте нам, включаючи всі особисті комунікації в чатах, каналах, платформах,
            додатках (включаючи Discord), які ми надали вам з метою отримання Послуг.
        </p>
    </div>
    <div class="subheader">
        7. Інтелектуальна власність
    </div>
    <div class="text">
        <p>
            <b>7.1.</b> Веб-сайт, творчий вміст, який ми надаємо або ділимося з нашими користувачами через Веб-сайт та
            поза ним, але в зв'язку з наданням Послуг, зокрема, за допомогою електронної пошти або за допомогою
            соціальних мереж, таких як Facebook, Twitter, YouTube, LinkedIn, Discord є інтелектуальною власністю
            FLOWSPOT, зокрема:
        </p>
        <p class="sub">
            <b>7.1.1.</b> Наші лекції, виступи або будь-які інші усні роботи, незалежно від форми, в якій вони записані,
            наприклад, аудіо, відео, пряма трансляція і т. д.;
        </p>
        <p class="sub">
            <b>7.1.2.</b> Наші товарні знаки та найменування;
        </p>
        <p class="sub">
            <b>7.1.3.</b> Наші письмові роботи, включаючи статті, книги, посібники, мемо, програми, навчальні плани,
            завдання
            тощо;
        </p>
        <p class="sub">
            <b>7.1.4.</b> Наші бази даних;
        </p>
        <p class="sub">
            <b>7.1.5.</b> Фотографії, зображення, ілюстрації;
        </p>
        <p class="sub">
            <b>7.1.6.</b> Похідні твори, що означає твори, які є творчим переробленням існуючих творів без шкоди охороні
            існуючого твору (анотація, адаптація, аранжування або інше перероблення твору) або творчий переклад його на
            іншу мову.
        </p>
        <p class="sub">
            <b>7.1.7.</b> Колекції робіт, колекції регулярних даних та інші складові роботи, які виникають внаслідок
            творчої
            роботи з вибору, координації або аранжування вмісту без шкоди авторському праву робіт, що включені до них як
            інтегровані частини;
        </p>
        <p>
            <b>7.2.</b> Творчий вміст не включає персональні дані, факти, закони, новини та іншу не-творчу інформацію.
        </p>
        <p>
            <b>7.3.</b> Наша інтелектуальна власність захищена авторським правом, товарними знаками та комерційною
            таємницею.
        </p>
        <p>
            <b>7.4.</b> Ваше право використовувати нашу інтелектуальну власність обмежується правами, які ми виражено
            надали
            вам відповідно до Умов та відповідної Угоди про надання Послуг і за умови повного виконання вами Умов та
            відповідної Угоди про надання Послуг («Ліцензія»).
        </p>
        <p>
            <b>7.5.</b> Ліцензія поширюється на особисте використання Веб-сайту і надає вам право переглядати,
            копіювати,
            завантажувати і друкувати нашу інтелектуальну власність лише для інформаційних цілей і отримання Навчальних
            Послуг з умовою, що ви не видаляєте з нашої інтелектуальної власності наші авторські права, товарні знаки та
            інші власні примітки.
        </p>
        <p>
            <b>7.6.</b> Ви не маєте права використовувати нашу інтелектуальну власність для будь-яких інших цілей або в
            будь-який інший спосіб.
        </p>
        <p>
            <b>7.7.</b> Відповідно до вищезазначеного, ми надаємо вам Ліцензію, яка:
        </p>
        <p class="sub">
            <b>7.7.1.</b> Обмежена, що означає,що ви можете використовувати інтелектуальну власність лише для цілей,
            визначених
            умовами та відповідною Угодою про надання Послуг;
        </p>
        <p class="sub">
            <b>7.7.2.</b> Неексклюзивна, що означає, що FLOWSPOT може надавати такі самі та подібні ліцензії іншим
            особам;
        </p>
        <p class="sub">
            <b>7.7.3.</b> Непередавана, що означає, що ліцензія надається лише вам і ви не можете передавати жодні з
            прав, які
            ми вам надаємо, іншій особі;
        </p>
        <p class="sub">
            <b>7.7.4.</b> Всесвітня, що означає, що ваша Ліцензія не обмежується територією, якщо чинне законодавство не
            забороняє таку Ліцензію в певних країнах;
        </p>
        <p class="sub">
            <b>7.7.5.</b> Скасовувана, що означає, що ми можемо припинити цю ліцензію за нашим розсудом.
        </p>
        <p>
            <b>7.8.</b> Поділяючи з нами ваші виконані завдання та надаючи нам доступ до вашого відгуку на будь-яких
            сторонніх
            публічних онлайн-ресурсах, інформації про профіль на Веб-сайті, ви надаєте нам світову, нескорувану, вічну,
            неексклюзивну, передавану ліцензію безоплатно експлуатувати такий вміст будь-яким чином. Права, які ви нам
            надаєте, використовуються тільки для обмеженої мети надання, пропонування та покращення наших Послуг.
        </p>
        <p>
            <b>7.9.</b> Ви несете повну відповідальність за весь ваш вміст. Передаючи свій вміст на Веб-сайт, ви
            заявляєте і
            гарантуєте, що ви є законним володарем прав на цей вміст та що ви маєте право надати нам ліцензію на
            використання вашого вмісту.Ви несете відповідальність за те, щоб вміст, який ви надаєте на Веб-сайт, не
            порушував авторські права, комерційну таємницю або інші права інтелектуальної власності іншої особи або
            суб'єкта. Ви несете відповідальність за будь-яку шкоду, що виникає внаслідок порушення авторських прав,
            комерційної таємниці або інших прав інтелектуальної власності або будь-якої іншої шкоди, що виникає в
            результаті завантаження, публікації або надсилання вмісту на цей Веб-сайт.
        </p>
        <p>
            <b>7.10.</b> Якщо ви вважаєте, що будь-який вміст на Веб-сайті порушує ваші права інтелектуальної власності,
            будь
            ласка, повідомте нас по електронній пошті.
        </p>
        <p>
            <b>7.11.</b> Ми залишаємо за собою право видаляти або відключати доступ до будь-якого вмісту в будь-який час
            і без
            попередження, якщо за нашим власним розсудом ми вважаємо, що будь-який вміст порушує Умови або належить до
            матеріалу, який заборонений законом.
        </p>
    </div>
    <div class="subheader">
        8.Конфіденційність
    </div>
    <div class="text">
        <p>
            <b>8.1.</b> Вам заборонено розголошувати або використовувати будь-які особисті дані та
            комерційні/торговельні
            секрети ("Конфіденційна інформація"), які ви отримали у зв'язку з використанням Веб-сайту, будь-якій третій
            особі з будь-яких цілей, крім виконання обов'язків або здійснення прав згідно з Умовами або відповідною
            Угодою про надання Послуг ("Конфіденційний режим").
        </p>
        <p>
            <b>8.2.</b> Сторони погодились, що разом з умовами Регламенту наступні події є порушенням Конфіденційного
            режиму:
        </p>
        <p class="sub">
            <b>8.2.1.</b> Розповсюдження матеріалів курсу, домашніх завдань, методик навчання;
        </p>
        <p class="sub">
            <b>8.2.2.</b> Порушення будь-яких прав інтелектуальної власності FLOWSPOT, як зареєстрованих, так і
            незареєстрованих, включаючи ноу-хау та методику навчання;
        </p>
        <p class="sub">
            <b>8.2.3.</b> Розповсюдження будь-якою формою інформації про процес навчання без нашої попередньої письмової
            згоди.
        </p>
    </div>
    <div class="subheader">
        9. Відмова від відповідальності
    </div>
    <div class="text">
        <p>
            <b>9.1.</b> Ви визнаєте, що Веб-сайт, Послуги та будь-яка інформація на Веб-сайті або доступна з Веб-сайту
            надаються на умовах "як є", і використання їх повністю на ваш власний ризик. Ви погоджуєтесь, що FLOWSPOT не
            несе відповідальності за будь-яку втрату або шкоду, пов'язану з використанням Веб-сайту.
        </p>
        <p>
            <b>9.2.</b> FLOWSPOT не є навчальним закладом і не видає жодних офіційних документів про освіту.
        </p>
    </div>
    <div class="subheader">
        10. Відшкодування
    </div>
    <div class="text">
        <p>
            <b>10.1.</b> Ви згодні відшкодувати FLOWSPOT, будь-яких його посадових осіб, директорів, співробітників,
            підрядників, агентів, а також пов'язані і пов'язані з ними юридичні особи від будь-яких претензій, витрат,
            втрат, зобов'язань, шкоди, витрат і судових рішень будь-якого характеру, що виникають з претензій, скарг,
            аудиту, запиту або іншого процесу, який виникає або стосується:
        </p>
        <p class="sub">
            <b>10.1.1.</b> Будь-якого фактичного або мовленого порушення ваших представлень, гарантій або зобов'язань,
            зазначених умовах;
        </p>
        <p class="sub">
            <b>10.1.2.</b> Неправомірного або неналежного використання Веб-сайту або доступної там інформації;
        </p>
        <p class="sub">
            <b>10.1.3.</b> Доступу або використання Веб-сайту іншою стороною через ваш обліковий запис з врахуванням
            обмежень,
            зазначених у цих Умовах.
        </p>
        <p>
            <b>10.2.</b> Ви не маєте права заявляти претензії будь-якого характеру проти FLOWSPOT за будь-яке
            невиконання наших
            зобов'язань згідно з Умовами внаслідок Форс-мажорних обставин - причин, поза нашим контролем, включаючи, але
            не обмежуючись, страйки, блокади, нестачу робочої сили або матеріалів, затримки в транспорті, отримання
            дозволів, згод або схвалень, необхідних FLOWSPOT для надання послуг, затримки з боку будь-якого нашого
            підрядника або постачальника, нещасних випадків будь-якого виду, заворушень або політичних, громадських або
            цивільних заворушень, війни або будь-яких військових дій, природних лих або вчинку держави або уряду,
            включаючи регулятивні заходи, накладені будь-якими іншими органами або організаціями, або будь-якою іншою
            причиною, незалежно від нашого безпосереднього контролю.
        </p>
    </div>
    <div class="subheader">
        11. Обмеження відповідальності
    </div>
    <div class="text">
        <p>
            <b>11.1.</b> В максимально дозволеному законом обсязі ми (а також наші компанії-групи, постачальники,
            партнери та агенти) не несемо відповідальності за будь-які непрямі, випадкові, або наслідкові збитки
            (включаючи втрату даних, доходу, прибутку або бізнес-можливостей, або особисті травми чи смерть), незалежно
            від того, чи виникають вони з контракту, гарантії, деліктної відповідальності, відповідальності за продукцію
            або іншим чином, навіть якщо ми були попереджені про можливість таких збитків заздалегідь. У деяких
            юрисдикціях не дозволяється виключення або обмеження відповідальності за наслідкові або випадкові збитки,
            тому деякі з цих положень можуть не застосовуватись до вас.
        </p>
    </div>
    <div class="subheader">
        12. Законодавство та вирішення спорів
    </div>
    <div class="text">
        <p>
            <b>12.1.</b> Положення цього розділу застосовуються до відносин між користувачами і нами щодо використання
            Веб-сайту.
        </p>
        <p>
            <b>12.2.</b> У відносинах між вами та нами з питань використання Веб-сайту та тлумачення умов, Сторони
            підлягають
            законодавству України.
        </p>
        <p>
            <b>12.3.</b> Будь-які спори між вами та нами, що виникають у зв'язку з використанням Веб-сайту, укладанням,
            виконанням, тлумаченням, недійсністю, припиненням або недійсністю Умов або виникають з цього або пов'язані з
            ними будь-яким чином, повинні бути вирішені судами, розташованими в Україні, які мають компетентність
            вирішувати спори згідно з українським законодавством. <br>
            &nbsp;&nbsp;&nbsp;&nbsp;Перед подачею позову проти нас ви згодні спробувати вирішити спір шляхом
            переговорів. Після цього, будь
            ласка, надішліть нам лист електронною поштою з вашими аргументами. Ми спробуємо вирішити спір неформально,
            відповідаючи на ваш лист. Якщо спір не вирішується протягом 15 днів після його подачі, ви або ми можемо
            розпочати офіційний судовий процес.

        </p>
    </div>
    <div class="subheader">
        13. Зміни
    </div>
    <div class="text">
        <p>
            <b>13.1.</b> Ми залишаємо за собою право в односторонньому порядку змінювати або модифікувати будь-яку
            частину Умов
            у будь-який час. Ми повідомимо зареєстрованих користувачів за 2 дні до дати набуття юридичної сили змін або
            нової версії Умов електронною поштою.
        </p>
        <p>
            <b>13.2.</b> Ваше подальше використання Веб-сайту після будь-яких таких змін є вашою згодою з останніми
            змінами Угоди користувача.
        </p>
    </div>
    <div class="subheader">
        14. Різне
    </div>
    <div class="text">
        <p>
            <b>14.1.</b> Умови замінюють будь-які інші домовленості між Сторонами, а також всі попередні версії. У разі
            розбіжностей між умовами цих Умов і відповідного Договору про надання послуг, умови відповідного Договору
            про надання послуг переважатимуть. Якщо будь-яке положення Умов буде визнане недійсним або невиконуваним, це
            не повинно вплинути на інші положення повністю або частково.
        </p>
        <p>
            <b>14.2.</b> Наше нездійснення будь-якого права або обов’язку не повинно розглядатися як відмова від такого
            права або обов’язку.
        </p>
        <p>
            <b>14.3.</b> Якщо у вас є будь-які питання щодо використання Веб-сайту або щодо Умов, звертайтеся до нас за
            адресою <a href="mailto:info@flowspot.trade">info@flowspot.trade</a>
        </p>
    </div>
    <p></p>
    <p></p>
    <p></p>
</div>