<div *ngIf='isRender&&testId!="first_test"' class="test-container">
    <div class="go-back-btn" (click)="this.backClicked()">
        <img src="../../../assets/images/go-back.png" alt="">
        Назад
    </div>
    <div class="main-div alternative">
        <div *ngFor="let item of test" class="question">
            <div class="question-header">{{item.question}}</div>
            <mat-radio-group id="{{item.id}}" id="{{item.id}}" class="answer-container" [labelPosition]='"after"'>
                <mat-radio-button value="a" (change)="radioChange($event,item.id)"
                    class="answer wrap-mat-radio-label">{{item.a}}</mat-radio-button>
                <mat-radio-button value="b" (change)="radioChange($event,item.id)"
                    class="answer wrap-mat-radio-label">{{item.b}}</mat-radio-button>
                <mat-radio-button *ngIf="item.c" (change)="radioChange($event,item.id)" class="answer wrap-mat-radio-label"
                    value="c">{{item.c}}</mat-radio-button>
                <mat-radio-button *ngIf="item.d" (change)="radioChange($event,(item.id))" class="answer wrap-mat-radio-label"
                    value="d">{{item.d}}</mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
    <span matTooltip="Будь ласка, дайте відповіді на всі запитання" matTooltipPosition="above"
        matTooltipClass="bg-danger" [matTooltipDisabled]="answerControl">
        <button mat-raised-button color="primary" type="button" class="test-end" [disabled]="!answerControl"
            (click)="checkAnswers()">
            Завершити тест
        </button>
    </span>
</div>