<div class="tg-int">
    <div class="banner-container">
        <div class="text-container">
            <div class="text">За статистикою, студенти, що підписані
                на наш телеграм в 4 рази з більшою ймовірністю завершують навчання і починають торгувати </div>
            <a mat-raised-button color="primary" class="tg-btn" href="https://t.me/flowspot_trade"
                target="_blank">Підписатися на канал</a>
        </div>
        <div class="content-container">
            <div class="banner">
                <div class="banner-smmile">
                    <img src="../../../../assets/images/smile-1.png" alt="">
                </div>
                <img src="../../../../assets/images/fs-tg-logo.png" class="fs-tg-logo">
                <div class="banner-text-container">
                    <div class="banner-h">
                        Flowspot.trade
                    </div>
                    <div class="banner-t">
                        Привіт! З приводу найбільш активних торгових годин можемо сказати…
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>