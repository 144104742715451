<div class="containers">
  <div class="image-container">
    <a routerLink="">
      <img src="./assets/images/logo.png" class="logo" alt="">
    </a>
  </div>
  <div class="first-section">
    <form [formGroup]="form" class="form" (ngSubmit)="onSubmit()">
      <div class="first-text">Увійдіть, щоб почати навчання</div>
      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" name="email" #userEmail />
        <mat-error *ngIf="this.form.controls['email'].errors?.['required']">Введіть значення</mat-error>
        <mat-error *ngIf="this.form.controls['email'].errors?.['email']">Будь ласка введіть коректний email</mat-error>
        <mat-error *ngIf="this.form.controls['email'].errors?.['invalid']">{{ERRORS[errorCode]}}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Пароль</mat-label>
        <input matInput formControlName="password" name="password" type="password" #userPassword />
        <mat-error *ngIf="this.form.controls['password'].errors?.['required']">Введіть значення</mat-error>
        <mat-error *ngIf="this.form.controls['password'].errors?.['minlength']">Мінімум 6 символів</mat-error>
        <mat-error *ngIf="this.form.controls['password'].errors?.['invalid']">{{ERRORS[errorCode]}}</mat-error>
      </mat-form-field>
      <span matTooltip="Будь ласка, заповніть усі поля вище." matTooltipPosition="above" matTooltipClass="bg-danger"
        [matTooltipDisabled]="form.valid">
        <button mat-raised-button color="primary" class="sign-in-btn" type="submit" [disabled]="form.invalid"
          (click)="this.signIn(userEmail.value, userPassword.value)">
          Увійти
        </button>
      </span>
      <a routerLink="/registration" class="register-link">
        Не маєте акаунт? Натисніть тут, щоб зареєструватися.
      </a>

      <div class="text-center">
        <button mat-raised-button color="primary" type="button" class="social-btn" (click)="signUpG()">
          <img src="./assets/images/googlelogo.png" class="googlelogo" alt="">
          Увійти за допомогою Google
        </button>

      </div>
    </form>
  </div>
  <div class="second-section">
    <div class="dog-image-container">
      <img src="./assets/images/logo3.png" class="logo-dog" alt="">
    </div>
  </div>
</div>