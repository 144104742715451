<div *ngIf="isRender" class="lesson-container">
    <div *ngIf="isRender" class="header">{{lesson.name}}</div>
    <youtube-player *ngIf="isRender" class="lesson-video" [videoId]="lesson.video" [height]="videoHeight" [width]="videoWidth">
    </youtube-player>
    <div class="description text">{{lesson.description}}</div>
    <div class="tasks-container">
        <div class="header" *ngIf="isRender&&lesson.tasks">
            Завдання:
            <div class="text" *ngFor="let task of this.tasks">{{task}}</div>
        </div>
    </div>
    <div class="links-container">
        <div class="header" *ngIf="isRender&&lesson.links">
            Посилання:
            <div class="text" *ngFor="let link of this.links">
                <a href="{{link.link}}" target="_blank">{{link.n}}</a>
            </div>
        </div>
    </div>
    <span *ngIf="isLabelRender"  [class.done]="this.isLabelDone" class="info-label">
        <img src="{{this.labelIconUrl}}" alt="">
        <div class="label-message">{{this.labelMessage}}</div>
    </span>
    <button mat-raised-button color="primary" class="nav-btn" (click)="navBtnClick()">{{this.navBtnMessage}}</button>
</div>