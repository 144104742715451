<div class="containers">
  <div class="first-section">
    <div class="image-container">
      <a routerLink="">
        <img src="./assets/images/logo.png" class="logo" alt="">
      </a>
    </div>
    <form [formGroup]="form" class="form" (ngSubmit)="onSubmit()">
      <div class="first-text">Зареєструйтеся, щоб почати навчання</div>
      <mat-form-field appearance="outline">
        <mat-label>Ім'я</mat-label>
        <input matInput formControlName="firstName" name="firstName" autofocus #userFirstName />
        <mat-error *ngIf="this.form.controls['firstName'].errors?.['required']">Введіть значення</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" name="email" #userEmail />
        <mat-error *ngIf="this.form.controls['email'].errors?.['required']">Введіть значення</mat-error>
        <mat-error *ngIf="this.form.controls['email'].errors?.['email']">Будь ласка введіть коректний email</mat-error>
        <mat-error *ngIf="this.form.controls['email'].errors?.['invalid']">{{ERRORS[errorCode]}}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Створити пароль</mat-label>
        <input matInput formControlName="password" name="password" type="password" #userPwd />
        <mat-error *ngIf="this.form.controls['password'].errors?.['required']">Введіть значення</mat-error>
        <mat-error *ngIf="this.form.controls['password'].errors?.['minlength']">Мінімум 6 символів</mat-error>
        <mat-error *ngIf="this.form.controls['password'].errors?.['invalid']">{{ERRORS[errorCode]}}</mat-error>
      </mat-form-field>
      <div class="pp-links"> Реєструючись на Flowspot, ви погоджуєтеся з нашими <a href="/terms&conditions">Умовами
          використання</a> та <a href="/privacy_policy">Політикою конфіденційності</a>. </div>
      <span matTooltip="Будь ласка, заповніть усі поля вище." matTooltipPosition="above" matTooltipClass="bg-danger"
        [matTooltipDisabled]="form.valid">
        <button mat-raised-button color="primary" class="sign-in-btn" type="submit" [disabled]="form.invalid"
          (click)="signUpWithEmailPassword(userEmail.value, userPwd.value, userFirstName.value)">
          Зареєструватися
        </button>
      </span>
      <a routerLink="/login" class="register-link">
        Вже маєте акаунт? Натисніть тут, щоб увійти.
      </a>

      <button mat-raised-button color="primary" type="button" class="social-btn" (click)="signUpG()">
        <img src="./assets/images/googlelogo.png" class="googlelogo" alt="">
        Увійти за допомогою Google
      </button>

    </form>
  </div>
  <div class="second-section">
    <div class="dog-image-container">
      <img src="./assets/images/logo3.png" class="dog-logo" alt="">
    </div>
  </div>
</div>