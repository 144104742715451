import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Firestore, collectionData, collection } from '@angular/fire/firestore';
import { getFirestore, doc, getDoc, docData } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from "rxjs/operators"
@Injectable({
  providedIn: 'root'
})
export class GetInfoService {
  constructor(private firestore: Firestore,
    private fns: AngularFireFunctions) {
    const db = getFirestore();
    const feedbacks = collection(this.firestore, 'feedbacks');
    this.feedbacks = collectionData(feedbacks);
    const stages = collection(db, 'stages');
    this.planStages = collectionData(stages);
  }
  feedbacks!: Observable<any>;
  planStages!: Observable<any>;
  questions!: Observable<any>;
  student!: Observable<any>;
  user!: Observable<any>;
  db = getFirestore();

  getUserInfo = async (id: string) => {
    const db = getFirestore();
    const docRef = doc(db, "users", id);
    this.user=docData(docRef);
    return this.user
  }
  getStudentInfo = async (id: string) => {
    const db = getFirestore();
    const docRef = doc(db, "students", id);
    this.student = docData(docRef)
    return this.student;
  }
  getFeedbacks = () => {
    return (this.feedbacks).pipe(map(res => {
      return res.sort((a: any, b: any) => b.name - a.name)
    }))
  }
  getLesson = async (id: string) => {
    const db = getFirestore();
    const docRef = doc(db, "lessons", id);
    const docSnap = await getDoc(docRef);
    return docSnap.data();
  }
  getTest = async (id: string) => {
    const db = getFirestore();
    const docRef = doc(db, "tests", id);
    const questions = collection(docRef, 'questions');
    this.questions = collectionData(questions);
    return (this.questions).pipe(map(res => {
      return res.sort((a: any, b: any) => a.id - b.id)
    }))
  }
  checkAnswers = async (answers: any) => {
    const checkAnswers = this.fns.httpsCallable('checkAnswers');
    return checkAnswers({answers})
  }
  getInviteLink = async () => {
    const getLink = this.fns.httpsCallable('generateInvite');
    return getLink({})
  }
}
