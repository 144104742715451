import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { GetInfoService } from '../../../shared/services/get-info.service';
import { AuthService } from '../../../shared/services/auth.service';
import { WriteDataService } from '../../../shared/services/write-data.service';
import { HelpersService } from '../../../shared/services/helpers.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoaderService } from '../../../shared/services/loading/loader.service'
import { ImageUploadService } from '../../../shared/services/image-upload.service';
import { concatMap } from 'rxjs/operators';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  public user: any;
  isRender: boolean = false;
  isShowDiscordBtn: boolean = false;
  id: any = '';
  form: FormGroup = this.fb.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    phone: ['', Validators.required],
    birthDate: ['', Validators.required],
    city: ['', Validators.required],
    discord: ['', Validators.required],
    interrogation: ['', Validators.required]
  });
  photoUrl: string = "../../../../assets/images/photo-default.png"
  constructor(
    public getInfoService: GetInfoService,
    public auth: AuthService,
    public wData: WriteDataService,
    private fb: FormBuilder,
    public router: Router,
    public helpers: HelpersService,
    public loadingService: LoaderService,
    public imageUploadService: ImageUploadService
  ) { }


  ngOnInit(): void {
    this.auth.uidAdded();
    this.helpers.setLastVisitedPage(this.router.url)
    this.auth.storageChange.subscribe((isAdd) => {
      if (isAdd) {
        this.id = localStorage.getItem('uid')
        this.getUser();
        this.getStudentInfo();
      }
    })
  }
  onSubmit() {

  }
  uploadImage(event: any): void {
    const id = localStorage.getItem('uid');
    if (event.target.files[0].type.includes("image") || event.target.files[0].name.includes("images[1]")) {
      if (event.target && id)
        this.imageUploadService.uploadImage(event.target.files[0], `images/profile/${id}`).pipe(
          concatMap((photoUrl) => this.wData.updateUserPhoto(photoUrl, id))
        ).subscribe({ error: (err) => console.log(err) })
    }
    else {
      alert("Можно обирати тільки зображення")
    }
  }
  getUser = async () => {
    (await this.getInfoService.getUserInfo(this.id!)).subscribe((res) => {
      if (res) {
        this.user = res;
        if (res.photo) {
          this.photoUrl = res.photo;
        }
        this.isRender = true;
      }
    });
  }
  getStudentInfo = async () => {
    (await this.getInfoService.getStudentInfo(this.id!)).subscribe((res) => {
      if (res) {

        if (res.fourth_test && res.profileFulled) {
          this.isShowDiscordBtn = true;
        }
        else {
          this.isShowDiscordBtn = false;
        }
      }
    })
  }
  addData = async (firstName: any, lastName: any, phone: string, birthDate: string, city: string, discord: string, interrogation: string) => {
    this.loadingService.setLoading(true);
    (await this.wData.addUserData(firstName, lastName, phone, birthDate, city, discord, interrogation)).subscribe(res => {
      this.loadingService.setLoading(false);
    })
  }
  btnClick = async () => {
    this.loadingService.setLoading(true);
    (await this.getInfoService.getInviteLink()).subscribe(link => {
      console.log(link)
      this.loadingService.setLoading(false);
      window.open(link, "_blank");
    })
  }
}
